import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _round from 'lodash/round';
import moment from 'moment'
import { celsiusToFahrenheit, fahrenheitToCelsius, getTemperatureUnitSuffix } from 'services/utils';
import { MEASUREMENT_SYSTEM_CHANGED_EVENT } from 'services/StudioEvents';

/* @ngInject */
export default class EmulatorHumidityController {
    constructor(
        EventEmitter,
        $scope,
        $rootScope,
        $state,
        $timeout,
        UserPreferencesManager
    ) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.UserPreferencesManager = UserPreferencesManager;
    }

    $onInit() {
        this.determineValues()

        // A fixed heartbeat interval is used for calculations
        this.heartbeatInterval = 15

        this.$rootScope.$on(MEASUREMENT_SYSTEM_CHANGED_EVENT, () => {
            this.determineValues()
        })

        this.shouldIncludeSamples = false
    }

    determineValues() {
        this.event = {
            humidity: {
                temperature: this.convertedTemperature(_get(this.device, 'reported.humidity.temperature', 0)),
                relativeHumidity: _get(this.device, 'reported.humidity.relativeHumidity', 0)
            }
        }
        
        this.min = this.convertedTemperature(-40)
        this.max = this.convertedTemperature(85)
        this.unitSuffix = getTemperatureUnitSuffix()
    }

    convertedTemperature(temperature) {
        return this.UserPreferencesManager.useFahrenheit
            ? _round(celsiusToFahrenheit(temperature), 2)
            : temperature;
    }

    ensureCelsius(temperature) {
        return this.UserPreferencesManager.useFahrenheit
            ? _round(fahrenheitToCelsius(temperature), 2)
            : temperature;
    }

    addRandomSamples(count) { // Populates the comma separated input fields with a specific number of values (centered around event value)
        this.inputTemperatureSamplesText = ''
        const eventValue = this.event.humidity.temperature
        for (let i = 0; i < count; i++) {
            const sample = Math.sin( (i+1)/2 ) + eventValue // Values are +/- 0.5
            this.inputTemperatureSamplesText += `${sample.toFixed(1)}, `
        }
        this.updateTemperatureSamples()

        this.inputHumiditySamplesText = ''
        const eventValueHumidity = this.event.humidity.relativeHumidity
        for (let i = 0; i < count; i++) {
            const sample = Math.max(0, Math.sin( (i+1)/2 ) + eventValueHumidity) // Values are +/- 0.5, but never below 0
            this.inputHumiditySamplesText += `${sample.toFixed(1)}, `
        }
        this.updateHumiditySamples()
    }

    updateTemperatureSamples() {
        const eventValue = this.event.humidity.temperature
        if (eventValue === null || eventValue === undefined) {
            return
        }
        // Replace everything except numerics
        this.inputTemperatureSamplesText = this.inputTemperatureSamplesText.replace(/[^0-9\.\,\-/\s]+/g, '') // eslint-disable-line no-useless-escape
        const trimmedInput = this.inputTemperatureSamplesText.replace(/\s+/g, '') // Trim input for spaces 
        this.temperatureSamples = trimmedInput.split(',').filter(Boolean)
        this.temperatureSamples.unshift(eventValue.toFixed(1)) // Include event value as first sample
        this.temperatureSamples.splice(15) // Ignore elements after we hit 15 samples
    }

    updateHumiditySamples() {
        const eventValue = this.event.humidity.relativeHumidity
        if (eventValue === null || eventValue === undefined) {
            return
        }
        // Replace everything except numerics
        this.inputHumiditySamplesText = this.inputHumiditySamplesText.replace(/[^0-9\.\,\-/\s]+/g, '') // eslint-disable-line no-useless-escape
        const trimmedInput = this.inputHumiditySamplesText.replace(/\s+/g, '') // Trim input for spaces 
        this.humiditySamples = trimmedInput.split(',').filter(Boolean)
        this.humiditySamples.unshift(eventValue.toFixed(1)) // Include event value as first sample
        this.humiditySamples.splice(15) // Ignore elements after we hit 15 samples
        
    }

    // Create an array of samples [{}, {}, ...]
    generateSamplesObject() { 
        const sampleInterval = this.heartbeatInterval / this.temperatureSamples.length
        const sampleIntervalDuration = moment.duration(sampleInterval, "minutes")
        this.formattedSampleInterval = `${sampleIntervalDuration.minutes()}m ${sampleIntervalDuration.seconds()}s`

        const output = []
        this.temperatureSamples.forEach((temperatureSample, index) => {
            const humiditySample = this.humiditySamples[index] || this.humiditySamples[this.humiditySamples.length - 1]
            if (temperatureSample !== '' && humiditySample !== '') {

                // Calculate offset from event timestamp
                const sampleTimeOffset = this.heartbeatInterval - (sampleInterval * (this.temperatureSamples.length - index)) 
                const duration = moment.duration(sampleTimeOffset, "minutes")

                let sampleTime = ''
                
                // First sample is equal to event value
                index === 0 ? sampleTime = moment().toISOString() : sampleTime = moment().subtract(duration).toISOString() // eslint-disable-line no-unused-expressions
                output.push({
                    temperature: Number(temperatureSample),
                    relativeHumidity: Number(humiditySample),
                    sampleTime
                })
            }
        }); 

        return output
    }

    sendHumidityEvent() {
        // Send value as Celsius, keep local state if Fahrenheit
        const sendEvent = _cloneDeep(this.event)
        sendEvent.humidity.temperature = this.ensureCelsius(sendEvent.humidity.temperature)

        if (this.shouldIncludeSamples) {
            // Check if event should include logged samples
            const samples = this.generateSamplesObject()
            if (samples.length > 1) {
                sendEvent.humidity.samples = samples
                // Ensure timestamp of event and first sample is equal
                sendEvent.humidity.updateTime = samples[0].sampleTime 
            }
        }
        
        this.onPublishEvent(
            this.EventEmitter({
                event: sendEvent
            })
        )
    }
}