import moment from 'moment';
import _sortedIndex from 'lodash/sortedIndex';
import { getTemperatureUnitSuffix, getHoursMinutesFormat } from 'services/utils';

// Returns the closest value to the target from a sorted array, along with its index.
// If two numbers are equally close to the target, the previous one is returned.
export function closestValue(arr, target) {
    const index = _sortedIndex(arr, target);

    if (index === 0) return { timestamp: arr[0], index: 0 };
    if (index === arr.length) return { timestamp: arr[arr.length - 1], index: arr.length - 1 };

    const prev = arr[index - 1];
    const next = arr[index];

    return Math.abs(prev - target) <= Math.abs(next - target)
        ? { timestamp: prev, index: index - 1 }
        : { timestamp: next, index };
}

export default {
    chart: {
        type: 'line',
        spacingLeft: 12,
        spacingRight: 12,
        spacingBottom: 8,
        spacingTop: 80,
        animation: false,
        zoomType: 'x'
    },
    tooltip: {
        enabled: true,
        followPointer: true,
        outside: true,
        shadow: false,
        animation: false,
        hideDelay: 0,
        borderRadius: 12,
        padding: 10,
        shared: true,
        useHTML: true,
        formatter() {

            // Find the closest points in time for each series
            const closestPoints = []
            const THRESHOLD = 3600000; // 60 minutes threshold to find closest points

            this.points[0].series.chart.series.forEach(series => {
                const result = closestValue(series.xData, this.x)
                if (Math.abs(result.timestamp - this.x) < THRESHOLD) {
                    closestPoints.push({
                        ...result,
                        series
                    })
                }
            })

            // Assume data is aggregated if all timestamps are the same
            const dataIsAggregated = closestPoints.every(point => point.timestamp === closestPoints[0].timestamp)
            let tooltipHtml = ''
            const showYear = moment(this.x).year() !== moment().year()
            if (dataIsAggregated) {
                // Shared time and date on top for all series
                const momentFormat = `${getHoursMinutesFormat()} ddd, MMM D${showYear ? ', YYYY' : ''}`
                const timestamp = moment(this.x).format(momentFormat);
                tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${timestamp}</p><table>`;

                this.points.forEach(function (entry) {
                    tooltipHtml += `<tr><td style="color: white; padding-right: 12px;"><span style="margin-right: 6px; -webkit-text-stroke: 1px #ffffff60;" class="highcharts-color-${entry.colorIndex}">\u25CF</span>${entry.series.name}</td><td style="font-weight:bold; text-align: right; color: white">${entry.y.toFixed(2)} ${getTemperatureUnitSuffix()}</td></tr>`;
                });

            } else {
                // Shared date on top, but individual timestamps for each series
                const headerFormat = `ddd, MMM D${showYear ? ', YYYY' : ''}`
                const seriesFormat = getHoursMinutesFormat()
                const headerTimestamp = moment(this.x).format(headerFormat);
                tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${headerTimestamp}</p><table>`
                closestPoints.forEach(point => {
                    if (!isNaN(point.series.yData[point.index])) {
                        tooltipHtml += `<tr><td style="color: white; padding-right: 12px;"><span style="margin-right: 6px; -webkit-text-stroke: 1px #ffffff60;" class="highcharts-color-${point.series.colorIndex}">\u25CF</span>${point.series.name}</td><td style="font-weight:bold; text-align: right; color: white">${point.series.yData[point.index].toFixed(2)} ${getTemperatureUnitSuffix()}<span style="font-weight: 400;"> | ${moment(point.timestamp).format(seriesFormat)}</span></td></tr>`;
                    }
                })
            }

            tooltipHtml += "</table>";
            return tooltipHtml;
        }
    },
    yAxis: [
        {
            allowDecimals: false,
            minPadding: 0.2,
            minTickInterval: 0.5,
            tickPixelInterval: 32,
            title: {
                enabled: false
            },
            labels: {
                get format() {
                    return `{value} ${getTemperatureUnitSuffix()}`;
                }
            },
            tickAmount: 5, // Ensure there are 5 ticks on the y-axis
            gridLineWidth: 1, // Show lines for the ticks
        },
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
        gridLineWidth: 1, // Show lines for the ticks
        type: 'datetime',
        crosshair: true,
        tickLength: 1,
    },
    legend: {
        verticalAlign: 'top',
        layout: 'vertical',
        floating: true,
        align: 'right',
        margin: 2,
        padding: 8,
        itemMarginBottom: 6,
        y: -80,
        navigation: {
            arrowSize: 10,
            activeColor: '#00fff'
        }
    },
    boost: {
        useGPUTranslations: true,
    },
    plotOptions: {

        line: {
            animation: false,
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            },
            findNearestPointBy: 'x'
        },
    }
};