import moment from 'moment';
import _merge from 'lodash/merge';
import ConfigPresets from 'services/charting/presets';
import { DataConverter } from 'services/charting'; 
import { getTemperatureUnitSuffix } from 'services/utils';

/* @ngInject */
export default class SensorChartController {
    constructor(SensorService, SensorEventsLoader, $scope) {
        this.SensorService = SensorService;
        this.SensorEventsLoader = SensorEventsLoader;
        this.$scope = $scope;
    }

    get timeRemaining() {
        const endTime = moment(this.validation.endTime);
        const now = moment();
        const duration = moment.duration(endTime.diff(now));
        const hours = duration.hours();
        const minutes = duration.minutes();
        if (hours === 0 && minutes === 0) {
            return '<1 minute'
        }
        return hours > 0 ? `${hours} hour${hours > 1 ? 's':''} ${minutes} minutes` : `${minutes} minutes`;
    }

    $onInit() {
        
        this.showConfirmCancelValidation = false
        
        this.SensorService.lookupDevice(this.validation.referenceSensorName.split('/')[3]).then((device) => {
            this.referenceSensor = device;
            this.showValidationPreview()
        }).catch((error) => {
            console.error(error) // eslint-disable-line no-console
        })

    }

    showValidationPreview() {
    
        this.loadingPreview = true

        const startTime = moment(this.validation.startTime)
        const paddedStartTime = moment(this.validation.startTime).subtract(1, 'hour')
        const endTime = moment(this.validation.endTime)
        const paddedEndTime = moment(this.validation.endTime).add(1, 'hour')

        const targetEvents = this.SensorEventsLoader.loadSince(this.device.name, ['temperature'], paddedStartTime, paddedEndTime).then((events) => {
            return events
        })
        const referenceEvents = this.SensorEventsLoader.loadSince(this.referenceSensor.name, ['temperature'], paddedStartTime, paddedEndTime).then((events) => {
            return events
        })


        Promise.all([targetEvents, referenceEvents]).then(([targetEventsResult, referenceEventsResult]) => {
            this.referenceSensor.events = referenceEventsResult
            this.device.events = targetEventsResult
            
            const chartSeries = [this.device, this.referenceSensor].map((device, index) => {
                let events = device.events
                if (events) {
                    events = DataConverter.getTemperatureSamples(events);                    
                    events.push([paddedEndTime.valueOf(), null]);

                }
                return {
                    data: events,
                    name: `${index === 0 ? 'Target' : 'Reference'}: ${device.labels.name || device.name.split('/').slice(-1)[0]}`,
                    tooltip: {
                        valueDecimals: 2,
                        valueSuffix: ` ${getTemperatureUnitSuffix()}`
                    },
                };
            })

            if (this.validation.status === 'SUCCESS') {
                // Add an additional series where the offset is applied to the target sensor
                const offset = this.validation.result.targetTemperatureOffset
                const offsetEvents = this.device.events
                offsetEvents.forEach(event => {
                    event.data.temperature.samples.forEach(sample => {
                        sample.value += offset
                    });
                })
                const offsetSeries = DataConverter.getTemperatureSamples(offsetEvents) 
                offsetSeries.push([paddedEndTime.valueOf(), null])
                chartSeries.splice(1, 0, {
                    data: offsetSeries,
                    name: `Target (Offset Applied): ${this.device.labels.name || this.device.name.split('/').slice(-1)[0]}`,
                    colorIndex: 16,
                    zIndex: 5,
                    tooltip: {
                        valueDecimals: 2,
                        valueSuffix: ` ${getTemperatureUnitSuffix()}`
                    }
                })
            }
    
            this.chartConfig = _merge(
                {
                    series: chartSeries
                },
                ConfigPresets.TemperatureValidation,
                {
                    title: { text: null },
                    credits: { enabled: false },
                    scrollbar: { enabled: false },
                    navigator: { enabled: false },
                    rangeSelector: { enabled: false },
                    chart: {
                        type: 'line',
                        spacingLeft: 8,
                        spacingRight: 8,
                        spacingBottom: 8,
                        spacingTop: 48,
                        animation: false,
                        zoomType: 'none'
                    },
                    xAxis: {
                        plotLines: [
                            {
                                color: '#FF0000',
                                width: 2,
                                value: startTime.valueOf(),
                                zIndex: 5,
                                label: {
                                    useHTML: true,
                                    align: 'center',
                                    y: -14,
                                    x: -3,
                                    allowOverlap: true,
                                    text: `<div style="transform: rotate(-90deg)">Start</div>`,
                                    
                                },
                            },
                            {
                                color: '#FF0000',
                                width: 2,
                                value: endTime.valueOf(),
                                zIndex: 5,
                                label: {
                                    useHTML: true,
                                    align: 'center',
                                    y: -14,
                                    x: -3,
                                    allowOverlap: true,
                                    text: `<div style="transform: rotate(-90deg)">End</div>`,
                                    
                                },
                                
                            }
                        ],
                        plotBands: [
                            {
                                color: '#FF0000',
                                from: startTime.valueOf(),
                                to: endTime.valueOf(),
                                zIndex: 5
                            }
                        ],
                    },
                    legend: {
                        verticalAlign: 'top',
                        layout: 'horizontal',
                        floating: true,
                        align: 'left',
                        margin: 2,
                        padding: 8,
                        itemMarginBottom: 6,
                        y: -48,
                        navigation: {
                            arrowSize: 10,
                            activeColor: '#00fff'
                        }
                    },
                    plotOptions: {
                        column: {
                            connectNulls: true,
                            getExtremesFromAll: false,
                        },
                        
                        series: {
                            animation: false,
                            shadow: false,
                            threshold: null,      
                            turboThreshold: 0,
                            boostThreshold: 0,
                            marker: {
                                enabled: false,
                            }
                        },
                    }
                }
            );
    
            this.loadingPreview = false
            

        }).catch((error) => {
            console.error(error) // eslint-disable-line no-console
        })
    } 

    showingResultForXMoreHours() {
        // Duration between now and the remove time
        const removeTime = moment(this.validation.completionTime).add(24, 'hour')
        const duration = moment.duration(removeTime.diff(moment()))
        const hours = Math.round(duration.asHours());
        if (hours === 0) {
            return '<1'
        }
        return hours
    }

    checkConfirmCancelValidation() {
        setTimeout(() => {
            this.showConfirmCancelValidation = true
            this.$scope.$applyAsync()
        }, 100);
    }

    undoCancelValidation() {
        this.showConfirmCancelValidation = false
    }

    cancelValidation() {
        const validationId = this.validation.name.split('/').slice(-1)[0]
        this.SensorService.cancelReferenceValidation(this.device.id, validationId).then(() => {
            this.onCancelValidation()
        }).catch((error) => {
            console.error(error) // eslint-disable-line no-console
        })
    }
}

