import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { noop } from 'rxjs';
import { DEFAULT_TOKEN, persistPageSize } from 'services/PaginationHelper';
import { scrollContainerToActiveChild } from 'services/utils';
import { CREATE_PROJECT } from 'services/Permissions';
import { cloneProject } from 'services/ProjectHelper';
import { LAST_VISITED_PROJECTS } from 'services/StudioProfileService';
import { getStatusMessage, animateSensorIcon, cconIs2ndGen, PROBE_STATES } from 'services/SensorHelper';
import imgCCON from '../../../assets/images/img_ccon.png';
import imgCCONv2 from '../../../assets/images/img_ccon_v2.png';
import imgMotion from '../../../assets/images/img_motion.png';
import imgCO2 from '../../../assets/images/img_co2.png';
import imgTemperatureHumidity from '../../../assets/images/img_temperature-humidity-side-illustration.png'
import imgProbeNotConnected from '../../../assets/images/probe_not_connected.png'
import imgProbe2WireConnected from '../../../assets/images/probe_2_wire.png'
import imgProbe3WireConnected from '../../../assets/images/probe_3_wire.png'
import imgProbe4WireConnected from '../../../assets/images/probe_4_wire.png'
import { States } from '../../../app.router';

const PAGE_SIZE_SUFFIX = 'projects';

function hasErrors(project) {
    return project.alertsSummary && project.alertsSummary.total.activeErrors > 0;
}

function hasWarnings(project) {
    return project.alertsSummary && project.alertsSummary.total.activeWarnings > 0;
}

/* @ngInject */
export default class ProjectsController {
    constructor(
        IAMService,
        DialogService,
        ToastService,
        $q,
        $state,
        $stateParams,
        ProjectManager,
        $timeout,
        AnalyticsService,
        SensorService,
        StudioProfileService,
        $scope
    ) {
        this.IAMService = IAMService;
        this.DialogService = DialogService;
        this.toastService = ToastService;
        this.$q = $q;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ProjectManager = ProjectManager;
        this.$timeout = $timeout;
        this.AnalyticsService = AnalyticsService;
        this.SensorService = SensorService;
        this.StudioProfileService = StudioProfileService;
        this.scope = $scope;
        this.imgCCON = imgCCON;
        this.imgCCONv2 = imgCCONv2;
        this.motionIcon = imgMotion;
        this.co2Icon = imgCO2;
        this.imgTemperatureHumidity = imgTemperatureHumidity;
        this.imgProbeNotConnected = imgProbeNotConnected
        this.imgProbe2WireConnected = imgProbe2WireConnected
        this.imgProbe3WireConnected = imgProbe3WireConnected
        this.imgProbe4WireConnected = imgProbe4WireConnected
        this.organizations = [];
    }

    get hasNoProjects() {
        const loadedWithoutErrors = !this.isFetching && this.firstFetchCompleted && !this.firstFetchError;
        const noSpecialCases = !this.query && !this.nextPageToken;
        return loadedWithoutErrors && noSpecialCases && !this.projects.length;
    }

    get noProjectsFound() {
        const noPagination = this.currentPageToken === DEFAULT_TOKEN && !this.nextPageToken;
        return !this.isFetching && this.query && !this.projects.length && noPagination;
    }

    // TODO: This currently use the search result to determine to show
    // this banner, but we should show this if the user has access to
    // any project with errors.  Need API support to be able to do
    // this efficiently.
    get showWarningBanner() {
        let ret = false;
        for (let i = 0; i < this.projects.length; i++) {
            if (hasErrors(this.projects[i])) {
                return false;
            }
            ret = ret || hasWarnings(this.projects[i]);
        }
        return ret;
    }

    get showErrorBanner() {
        return this.projects.reduce((acc, elem) => acc || hasErrors(elem), false);
    }

    get foundSensor() {
        return Object.keys(this.device).length;
    }

    get statusMessage() {
        return getStatusMessage(this.device);
    }

    get foundProject() {
        return Object.keys(this.project).length;
    }

    get isProbeSensor() {
        return this.device?.productNumber?.includes('102772') || this.device?.productNumber?.includes('102774')
    }

    get isTemperatureAndHumiditySensor() { // Large form factor
        // Emulated sensors use a label to inject the product number
        if (this.device?.labels?.['emulated-product-number'] === '102892') {
            return true
        }
        return this.device?.productNumber?.includes('102892') || this.device?.productNumber?.includes('102895')
    }

    get probeIcon() {
        switch (this.probeState) {
            case PROBE_STATES.NOT_CONNECTED || PROBE_STATES.PROBE_WIRE_STATE_UNKNOWN:
                return this.imgProbeNotConnected
            case PROBE_STATES.TWO_WIRE:
                return this.imgProbe2WireConnected
            case PROBE_STATES.THREE_WIRE:
                return this.imgProbe3WireConnected
            case PROBE_STATES.FOUR_WIRE:
                return this.imgProbe4WireConnected
            default:
                return this.imgProbeNotConnected
        }
    }


    $onInit() {
        this.query = this.$stateParams.query;
        this.isCreateBtn = true;

        this.currentPageSize = 100;
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;

        this.selectedProject = null;
        this.selectedOrganization = null;
        this.isSingleOrganization = false;

        this.firstFetchCompleted = false;
        this.firstFetchError = false;
        this.isFetching = false;
        this.projects = [];
        this.query = "";

        this.searchType = 'projects'
        this.deviceSearch = false

        this.deviceInput = ''
        this.showMissingDeviceAccess = false

        // Device ID Lookup
        this.device = {};
        this.project = {};
        this.lookupIDFetching = false;

        this.lastVisitedProjectsIds = []

        this.organizations = this.fetchAllOrganizations();
        this.organizations.then((orgs) => {
            if (orgs.length <= 1) {
                this.isSingleOrganization = true;
            }
            // Default to the current project organization.
            const currentOrg = _find(orgs, { name: this.ProjectManager.currentProject.organization });

            const promises = orgs.map(org => this.IAMService.organizationPermissions(org.name));
            this.$q.all(promises).then((permissions) => {
                const organizations = [];

                for (let i = 0; i < orgs.length; i++) {
                    if (permissions[i].data.includes(CREATE_PROJECT)) {
                        organizations.push(orgs[i]);
                    }
                }

                this.organizations = organizations;

                // Use the current organization if possible, 
                // otherwise default to the first organization with CREATE_PROJECT
                this.selectedOrganization = currentOrg || this.organizations[0];
            });


            this.requestParams = {
                query: this.query,
                pageSize: this.currentPageSize,
                pageToken: this.currentPageToken
            };


            this.fetchProjects().then(() => this.$timeout(scrollContainerToActiveChild));
        });
    }

    selectProject(project) {

        if (project.id === this.ProjectManager.currentProjectId) { // Selected the current Project
            this.closeModal() 
        }

        this.ProjectManager.setCurrentProject(cloneProject(project));

        setTimeout(function () {
            if (this.$state.current.name === States.DASHBOARD || this.$state.current.name === States.DASHBOARD_SPECIFIED) {
                this.$state.go(States.DASHBOARD, {
                    projectId: project.id
                })
            } else if (this.$state.current.name === States.SENSOR_DETAILS) {
                this.$state.go(States.SENSORS, {
                    projectId: project.id
                })
            } else if (this.$state.current.name === States.RULE_DETAIL) {
                this.$state.go(States.RULES, {
                    projectId: project.id
                })
            } else if (this.$state.current.name === States.DATA_CONNECTOR_DETAILS) {
                this.$state.go(States.DATA_CONNECTORS, {
                    projectId: project.id
                })
            } else if (this.$state.current.name === States.SERVICE_ACCOUNT_DETAILS) {
                this.$state.go(States.SERVICE_ACCOUNTS, {
                    projectId: project.id
                })
            } else if (this.$state.current.name === States.EMULATOR_DETAILS) {
                this.$state.go(States.EMULATOR, {
                    projectId: project.id
                })
            } else if (this.$state.current.name === States.PROJECTS) {
                this.$state.go(States.SENSORS, {
                    projectId: project.id
                })
            } else {
                this.$state.go(this.$state.current, {
                    projectId: project.id
                })
            }
        }.bind(this), 200); // Short delay to visually indicate project selection
    }

    fetchAllOrganizations() {
        const deferred = this.$q.defer();
        this.getOrganizationsPage(null, [])
            .then(orgs => deferred.resolve(orgs))
            .catch(() => {
                this.ToastService.showSimpleTranslated('organization_list_error');
                // Automatically close the dialog if this fails.
                deferred.reject();
            });
        return deferred.promise;
    }

    getOrganizationsPage(pageToken, previousData) {
        return this.IAMService
            .organizations({ pageToken })
            .then(({ data, nextPageToken }) => {
                const allData = [...previousData, ...data];
                if (nextPageToken !== '') {
                    return this.getOrganizationsPage(nextPageToken, allData);
                }
                return allData;
            });
    }

    getOrganizations(searchText) {
        return this.organizations.then((organizations) => {
            if (searchText === '') {
                return organizations;
            }
            const lowerSearchTerm = searchText.toLowerCase();
            return _filter(organizations, o => o.displayName.toLowerCase().search(lowerSearchTerm) !== -1);
        });
    }

    projectQueryChange(query) {
        // Catch case when user clears input
        return query === "" ? this.loadDataByQuery(query) : noop
    }

    loadDataByQuery(query) {
        this.query = query;
        this.requestParams.query = query;
        this.requestParams.pageToken = DEFAULT_TOKEN;
        return this.fetchProjects();
    }

    showCreateForm() {
        this.isCreateBtn = false;
        this.isCreateForm = true;
    }

    showCreateBtn() {
        this.isCreateBtn = true;
        this.isCreateForm = false;
        this.name = null;
    }

    submit() {
        this.processing = true;
        this.IAMService.createProject({
            displayName: this.name,
            organization: this.selectedOrganization.name
        })
            .then((project) => {
                setTimeout(() => {
                    this.projects.unshift(project);
    
                    this.name = null;
                    this.addProjectForm.$setPristine();
                    this.addProjectForm.$setUntouched();
    
                    this.showCreateBtn();
    
                    this.toastService.showSimpleTranslated('project_was_created');
    
                    this.$state.go(States.SENSORS, {
                        projectId: project.id,
                        justCreated: true,
                        project
                    });
                }, 300);
            }, (serverResponse) => {
                this.toastService.showSimpleTranslated('project_wasnt_created', {
                    serverResponse
                });
            })
            .finally(() => {
                this.processing = false;
            });
    }

    setPageToken({ pageToken }) {
        this.requestParams.pageToken = pageToken;
        this.fetchProjects();
    }

    setPageSize({ pageSize }) {
        persistPageSize(pageSize, PAGE_SIZE_SUFFIX);
        this.requestParams.pageToken = DEFAULT_TOKEN;
        this.requestParams.pageSize = pageSize;
        this.fetchProjects();
    }

    onSearchTypeChange() {
        if (this.searchType === 'device') {
            this.deviceSearch = true
            this.projects = []
            this.deviceInput = this.query
            this.query = ''
            setTimeout(() => {
                document.getElementById("deviceInput").focus();
                if (this.deviceInput) {
                    this.onDeviceInputChange()
                }
            }, 600);
        } else {
            this.deviceSearch = false
            this.loadDataByQuery('')
            this.deviceInput = ''
            setTimeout(() => {
                document.getElementById("autoCompleteId").focus();
            }, 400);
        }
    }

    onDeviceInputChange() {
        if (this.deviceInput.length === 20 || this.deviceInput.length === 23) {
            this.getDevice(this.deviceInput)
        } else {
            this.device = {}
        }
    }

    getDevice(deviceId) {
        // Search across all the users projects by setting project to '-'
        this.showMissingDeviceAccess = false
        this.lookupIDFetching = true;

        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }

        this.SensorService.getSensor('-', deviceId).then(device => {
            const projectId = device.name.split('/')[1];
            // Listen for device events (show live touches)
            this.eventSubscription = this.SensorService
                .createObservableFromThingUpdates(device.id, projectId)
                .subscribe(event => this.onNewEvents(event));
            this.IAMService.getProject(projectId).then(project => {
                this.project = project;
                this.device = device;
                if (this.isProbeSensor) {
                    this.probeState = _get(device, 'reported.probeWireStatus.state', PROBE_STATES.NOT_CONNECTED)
                }
                this.projects = [this.project];
                this.lookupIDFetching = false;
            });

            this.AnalyticsService.trackEvent('device_id_lookup.found')
        }).catch((err) => {
            this.AnalyticsService.trackEvent(`device_id_lookup.${err.status === 404 ? "not_found" : `error.${err.status}`}`)
            this.showMissingDeviceAccess = true
            this.device = {};
            this.lookupIDFetching = false;
        }).finally(() => {
            this.firstFetchCompleted = true;
            this.isFetching = false;
        });
    }

    ccon2ndGen(device) { // eslint-disable-line class-methods-use-this
        return cconIs2ndGen(device)
    } 


    fetchProjects() {
        this.isFetching = true;
        this.projects = [];
        this.device = {};

        const deferred = this.$q.defer();
        this.IAMService.projects(this.requestParams).then(({ data, nextPageToken }) => {
            this.projects = data;
            deferred.resolve([]);

            if (this.requestParams.query) {
                // The user has entered a search string, so we want to track the search results
                this.AnalyticsService.trackEvent(`project_search.${data.length > 0 ? 'found' : 'not_found'}`)
            }
            
            // Show the recently viewed projects if the user has many projects and an empty search
            if (this.projects.length >= 10 && this.requestParams.query === "") {

                const lastVisitedProjectsIds = this.StudioProfileService.get(LAST_VISITED_PROJECTS) || []
                this.lastVisitedProjectsIds = [...lastVisitedProjectsIds]
                
                // Remove current project from list of recently visited projects
                const currentProjectIndex = this.lastVisitedProjectsIds.indexOf(this.ProjectManager.currentProjectId)
                if (currentProjectIndex > -1) {
                    this.lastVisitedProjectsIds.splice(currentProjectIndex, 1)
                }

                // Recently viewed projects are shown at the top by having a rank >0, the remaining will have -1
                this.projects.forEach(project => {
                    project.lastVisitedRank = this.lastVisitedProjectsIds.indexOf(project.id) // -1 if not found
                });
    
                // Optionally fetch recently viewed projects if they're not already fetched
                const projectIds = this.projects.map(project => project.id)
                for (let index = 0; index < this.lastVisitedProjectsIds.length; index++) {
                    const projectId = this.lastVisitedProjectsIds[index]
                    if (!projectIds.includes(projectId)) {
                        this.IAMService.getProject(projectId).then(project => {
                            project.lastVisitedRank = index
                            this.projects.push(project)
                        })
                    }
                }
            }

            this.currentPageSize = this.requestParams.pageSize;
            this.currentPageToken = this.requestParams.pageToken;
            this.nextPageToken = nextPageToken;
        })
            .catch((serverResponse) => {
                if (!this.firstFetchCompleted) {
                    this.firstFetchError = true;
                }
                this.toastService.showSimpleTranslated('projects_wasnt_fetched', {
                    serverResponse
                });
                deferred.reject();
            })
            .finally(() => {
                this.isFetching = false;
                this.firstFetchCompleted = true;
            });
        return deferred.promise;
    }

    onNewEvents(event) {
        this.device.reported = {
            ...this.device.reported,
            ...event.data
        }
        if (event.eventType === 'probeWireStatus') {
            this.probeState = _get(this.device, 'reported.probeWireStatus.state', PROBE_STATES.NOT_CONNECTED);
        }
        if (event.eventType === 'touch') {
            this.rippleOnSensor();
        }
        this.scope.$applyAsync();
    }

    rippleOnSensor() {
        this.sensorIconNode = document.getElementById('lookup-sensor-icon');    
        animateSensorIcon(this.sensorIconNode);
    }

    goToSensorPage() {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: this.project.id,
            sensorId: this.device.id
        });
        this.DialogService.cancel()
    }

    saveProject({ project, patch }) {
        this.IAMService
            .updateProject(project.name, patch)
            .then(() => {
                const projectRef = this.projects.find(item => item.id === project.id);
                Object.assign(projectRef, project);

                if (this.ProjectManager.currentProjectId === project.id) {
                    this.ProjectManager.setCurrentProject(cloneProject(project));
                }

                this.toastService.showSimpleTranslated('project_was_updated');
            }, (serverResponse) => {
                this.toastService.showSimpleTranslated('project_wasnt_updated', {
                    serverResponse
                });
            });
    }

    static hasErrors(project) {
        return hasErrors(project);
    }

    static hasWarnings(project) {
        return hasWarnings(project) && !hasErrors(project);
    }

    closeModal() {
        this.DialogService.cancel()
    }

    $onDestroy() {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
    }
}
