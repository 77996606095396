import moment from 'moment';
import * as _ from "lodash";
import {
    getResourceName
} from 'services/api/helper';
import { 
    DASHBOARD_APPEARANCE_CO2_HEATMAP, 
    DASHBOARD_APPEARANCE_MOTION_HEATMAP,
    DASHBOARD_APPEARANCE_MOTION_HISTOGRAM,
    DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED,
    DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE,
    DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY,
    DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN,
    DASHBOARD_AGGREGATED_HEATMAP_DAYS_THRESHOLD
} from 'constants/device';
import * as SensorTypes from '../config/SensorTypes';
import Resource from './Resource';

const alpha = '/v2alpha';
const LAST_VISITED_DASHBOARD = 'last-visited-dashboard'

export const MAX_CARDS = 25;
export const MAX_DEVICES_PER_MULTI_CARD = 10;

/* @ngInject */
export default class DashboardService {
    constructor($http, ProjectManager, AuthService, SensorService, SensorEventsLoader, $q, StudioProfileService, FeatureFlags) {
        this.$http = $http;
        this.ApiService = new Resource($http, alpha);
        this.ProjectManager = ProjectManager;
        this.AuthService = AuthService;
        this.SensorService = SensorService;
        this.SensorEventsLoader = SensorEventsLoader;
        this.$q = $q;
        this.StudioProfileService = StudioProfileService;
        this.FeatureFlags = FeatureFlags;
    }

    get hasLongTermStorageFeatureFlag() {
        return this.FeatureFlags.isActive('sensor_long_term_storage')
    }

    setLastVisitedDashboard(projectId, dashboardId) {
        this.StudioProfileService.set(`${LAST_VISITED_DASHBOARD}_${projectId}`, dashboardId)
    }

    getLastVisitedDashboard(projectId) {
        const lastVisitedDashboard = this.StudioProfileService.get(`${LAST_VISITED_DASHBOARD}_${projectId}`)
        if (lastVisitedDashboard) {
            return lastVisitedDashboard
        }
        return ''
    }

    createDashboard(projectId, config) {
        const name = getResourceName(['projects', projectId], ['project.dashboards'])
        return this.ApiService.create(name, config)
            .catch((error) => {
                throw error
            })
    }

    deleteDashboard(projectId, dashboardId) {
        const name = getResourceName(['projects', projectId], ['project.dashboards', dashboardId])
        return this.ApiService.remove(name)
            .catch((error) => {
                throw error
            })
    }

    listDashboards(projectId) {
        const name = getResourceName(['projects', projectId], ['project.dashboards'])
        return this.ApiService.get(name)
            .catch((error) => {
                throw error
            })
    }
    
    getDashboardConfig(projectID, dashboardId) {
        const name = getResourceName(['projects', projectID], ['project.dashboards', dashboardId])
        return this.ApiService.get(name)
            .catch((error) => {
                // If no dashboard was found for this project, return an empty dashboard.
                // For all other errors, throw it to the receiver
                if (error.status === 404) {
                    return {
                        columnCount: 4,
                        dashboardType: "TILED",
                        duration: "LAST_7_DAYS",
                        dashboardId: 'default',
                        displayName: 'Default Dashboard',
                        cards: []
                    };
                }
                throw error;
            })
    }

    updateDashboardConfig(projectID, dashboardConfig) {
        const name = getResourceName(['projects', projectID], ['project.dashboards', dashboardConfig.dashboardId])
        return this.ApiService.put(name, dashboardConfig)
    }

    // cardConfig: Should be the same structure as received from the dashboard endpoint
    // startTime: Must be a moment object
    // endTime: Must be a moment object
    getCardContent(projectID, cardConfig, startTime, endTime) { 
        
        // Copy the start and end time to avoid modifying the original objects
        let fetchStart = moment(startTime)
        let fetchEnd = moment(endTime)

        if (cardConfig.appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP || 
            cardConfig.appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP ||
            cardConfig.appearance === DASHBOARD_APPEARANCE_MOTION_HISTOGRAM ||
            cardConfig.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
            // For these card types we want to extend startTime and endTime to full days
            fetchStart.set({ hour:0, minute:0, second:0, millisecond:0 })
            fetchEnd.set({ hour:23, minute:59, second:59, millisecond:0 }) // TODO: This can probably be changed to midnight the next day when migration to device-history is done
            
            // We don't want to show less than a week's worth of data for heatmaps. Stretch start and end to show full weeks (Mon-Sun)
            if (cardConfig.appearance !== DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
                fetchStart = fetchStart.startOf('isoWeek') 
                fetchEnd = fetchEnd.endOf('isoWeek')
            } 
        }
        switch (cardConfig.cardType) {
            case "DEVICE":
                return this.getDeviceCardContent(
                    projectID, 
                    cardConfig, 
                    fetchStart, 
                    fetchEnd
                );
            default:
                // Unknown card type
                return null;
        }
    }

    // Private
    // 
    // Preconditions:
    // * Only one device type is present (can be multiple devices, but they have to be the same type)
    // * At least one of includeStats and includeEvents is set to true
    //
    // Output structure:
    /**
     * {
     *   "devices": [
     *     { "name": "projects/proj1/devices/device1", "type": "temperature", "reported": ... }
     *     { "name": "projects/proj1/devices/device2", "type": "temperature", "reported": ... }
     *   ],
     *   "notFoundDeviceIDs": [ 
     *      "device3", "device4" 
     *   ],
     *   "stats": { // Present if includeStats is true, and device is not a counting sensor
     *     "projects/proj1/devices/device1": { "MIN": ..., "MAX": ..., "AVERAGE": ... },
     *     "projects/proj1/devices/device2": { "MIN": ..., "MAX": ..., "AVERAGE": ... }
     *   },
     *   "events": { // Present if includeEvents is true
     *     "projects/proj1/devices/device1": [ { "event": { "eventType": "temperature", "data": ... }}, { "event": ... } ],
     *     "projects/proj1/devices/device2": [ { "event": { "eventType": "temperature", "data": ... }}, { "event": ... } ]
     *   }
     *   "networkStatusCounts": {
     *     "projects/proj1/devices/device1": [ { "count": ..., "updateTime": ... }, { "count": ..., "updateTime": ... } ],
     *     "projects/proj1/devices/device2": [ { "count": ..., "updateTime": ... }, { "count": ..., "updateTime": ... } ],
     *   }
     * }
     */
    getDeviceCardContent(projectID, cardConfig, startTime, endTime) {
        const deviceConfig = cardConfig.deviceConfig
        // A promise that will resolve to the list of the requested devices
        const devicesPromise = this.SensorService.getFromCache(deviceConfig.deviceIds);

        // A promise that will resolve to a list of events results and stats, one per device.
        // Will come from either event aggregation or event history service, depending
        // on the window size.
        // Output format:
        /**
         * {
         *   "stats": { // Present if includeStats is true, and device is not a counting sensor
         *     "projects/proj1/devices/device1": { "MIN": ..., "MAX": ..., "AVERAGE": ... },
         *     "projects/proj1/devices/device2": { "MIN": ..., "MAX": ..., "AVERAGE": ... }
         *   },
         *   "events": { // Present if includeEvents is true
         *     "projects/proj1/devices/device1": [ { "event": { "eventType": "temperature", "data": ... }}, { "event": ... } ],
         *     "projects/proj1/devices/device2": [ { "event": { "eventType": "temperature", "data": ... }}, { "event": ... } ]
         *   },
         *   "networkStatusCounts": {
         *     "projects/proj1/devices/device1": [ { "count": ..., "updateTime": ... }, { "count": ..., "updateTime": ... } ],
         *     "projects/proj1/devices/device2": [ { "count": ..., "updateTime": ... }, { "count": ..., "updateTime": ... } ],
         *   }
         */
        const eventsPromise = devicesPromise.then(deviceResponse => {
            // Check if the card holds a deleted/removed device the user does not have access to
            if (deviceResponse.notFoundDeviceIDs.length > 0) {
                return null;
            }
            
            // Get the device type based on the devices array.
            const devices = deviceResponse.devices.filter(device => device.name.split("/")[1] === projectID);

            if (devices.length === 0) {
                return null;
            }

            const deviceTypes = _.uniq(devices.map(d => d.type));

            const deviceNames = devices.map( device => device.name )

            // Make sure at least stats or events have been requested
            if (deviceConfig.includeEvents === false && deviceConfig.includeStats === false) {
                // At least one of includeEvents and includeStats has to be set to true
                return null;
            }

            // Don't load motion events if we're only showing live current status
            if (devices.length > 1 && deviceTypes[0] === "motion") {
                return null
            }

            // Don't load proximity events if we're only showing live current status
            if (devices.length > 1 && deviceTypes[0] === "proximity") {
                return null
            }

            // Don't load deskOccupancy events if we're only showing live current status
            if (devices.length > 1 && deviceTypes[0] === "deskOccupancy" && cardConfig.appearance !== DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
                return null
            }

            // Creating one promise per endpoint, and defaulting them to resolve
            let aggregatePromise = Promise.resolve()
            let historyPromise   = Promise.resolve()

            const windowSizeSeconds = this.getWindowSizeSeconds(startTime, endTime, deviceTypes);
            const wantsRawData = DashboardService.shouldGetRawEvents(startTime, endTime, deviceTypes, cardConfig);

            // Check if there is a project location, will return '' if not
            let timeLocation = this.ProjectManager.currentProjectTimeLocation

            // Use the browser's time location if there is no project location
            if (timeLocation === '' && typeof Intl !== "undefined") {
                timeLocation = Intl.DateTimeFormat?.().resolvedOptions().timeZone;
            }

            // Check if a request should be sent to the aggregateData endpoint
            if (deviceConfig.includeStats || (deviceConfig.includeEvents && wantsRawData === false)) {
                const aggregatePayload = DashboardService.createAggregateRequestPayload(
                    deviceNames, 
                    deviceTypes,
                    cardConfig,
                    startTime, 
                    endTime,
                    wantsRawData,
                    windowSizeSeconds,
                    timeLocation
                )
                if (aggregatePayload) {
                    aggregatePromise = this.SensorService.aggregatedEvents(aggregatePayload)
                }
            }

            // Check if a request should be sent to the event history endpoint
            // The proximity, water and motion events are currently not sent to the aggregate endpoint as
            // we want each event individually. This might change in the future.
            if (wantsRawData) {
                // Create a promise that loads events for all the available 
                historyPromise = Promise.all(devices.map(device => {
                    let eventType = SensorTypes[device.type].history.eventType
                    if (cardConfig.appearance === DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE) {
                        // Ensure CO2 sensors loads temperature (from humidity events)
                        if (device.type !== "temperature") {
                            eventType = "humidity"
                        }
                    }
                    if (cardConfig.appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY) {
                        // Ensure CO2 sensors loads humidity
                        eventType = "humidity"
                    }
                    return this.SensorEventsLoader.loadSince(device.name, [eventType], startTime, endTime)
                }))
            }

            // Structure the events and stats in a standardized format
            return Promise.all([aggregatePromise, historyPromise]).then(([aggregates, histories]) => {

                // Set the output with one object per device name
                const output = { stats: {}, events: {}, networkStatusCounts: {} };

                // Add the events from histories, if we have them
                if (histories && histories.length && histories.length > 0) {
                    histories.forEach(deviceEvents => {
                        // Check if there are any events for this device. Skip if there aren't
                        if (!deviceEvents || !deviceEvents.length || deviceEvents.length === 0) { return; }

                        // Add the histories events to our output
                        const deviceID = deviceEvents[0].targetName.split('/').slice(-1);
                        const deviceName = `projects/${projectID}/devices/${deviceID}`;
                        
                        // Convert humidity events to temperature events if the card is configured to show only temperature
                        // This can then be converted to Highchart data with the other data
                        
                        if (cardConfig.appearance === DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE) {
                            if (deviceEvents[0].eventType === "humidity") {
                                // eslint-disable-next-line no-param-reassign
                                deviceEvents = deviceEvents.map(event => ({
                                    "targetName": deviceName,
                                    "eventType": "temperature",
                                    "timestamp": event.timestamp,
                                    "data": {
                                        "temperature": {
                                            "value": event.data.humidity?.temperature,
                                            "updateTime": event.timestamp
                                        }
                                    }
                                }))
                            } 
                        }
                        output.events[deviceName] = deviceEvents;
                    })
                }

                // Add the events and stats from aggregated events
                if (aggregates && aggregates.results && aggregates.results.length > 0) {
                    aggregates.results.forEach(result => {
                        const deviceName = result.device;

                        // Add the stats
                        output.stats[deviceName] = result.stats;
                        
                        const networkStatusCounts = []
                        result.values.forEach(value => {
                            if (!isNaN(value.values["networkStatus.signalStrength_COUNT"])) {
                                networkStatusCounts.push({
                                    "count": value.values["networkStatus.signalStrength_COUNT"],
                                    "updateTime": new Date(value.timeWindow).getTime()        
                                })
                            }
                        })

                        output.networkStatusCounts[deviceName] = networkStatusCounts

                        // Add the aggregated events, if no events are set yet (from history)
                        if (!output.events[deviceName]) {
                            let events = [];
                            result.values.forEach(value => {
                                const deviceEvents = DashboardService.convertAggregateValueToDeviceEvents(deviceName, value);
                                if (deviceEvents) {
                                    // Add the dashboard event(s) to the output events (could be a single value
                                    // or an array).
                                    events = events.concat(deviceEvents);
                                }
                            })
                            output.events[deviceName] = events;
                        }
                    })
                }
                return output;
            })
        })

        // Joins together the results of the devices request and the events requests to
        // one object before returning it.
        return Promise.all([devicesPromise, eventsPromise]).then(([deviceResponse, eventData]) => {

            // Provide empty default values if device is fetched without stats or events.
            // This will be the case if one of the devices in the card were not found.
            if (eventData === null) { 
                return {
                    devices: deviceResponse.devices,
                    notFoundDeviceIDs: deviceResponse.notFoundDeviceIDs,
                    stats: {},
                    events: {},
                    networkStatusCounts: {}
                };
            }
            
            // Inject the device fields into the output
            eventData.devices = deviceResponse.devices;
            // This is only included for completeness. The logic above will ensure that this is always empty.
            eventData.notFoundDeviceIDs = deviceResponse.notFoundDeviceIDs;
            
            return eventData;
        })
    }

    // Private
    // Returns the number of days between the two dates. The dates can be any format
    // accepted by moment, including a moment object.
    static daysBetween(startTime, endTime) {
        return moment.duration(endTime.diff(startTime)).asDays();
    }

    // Private
    // Returns a bool indicating whether or not events should be fetched from the
    // historical endpoint (to get raw events), or the aggregated endpoint.
    static shouldGetRawEvents(startTime, endTime, deviceTypes, cardConfig) {

        // Never fetch raw events for aggregated durations
        if (cardConfig.appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP ||
            cardConfig.appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP ||
            cardConfig.appearance === DASHBOARD_APPEARANCE_MOTION_HISTOGRAM ||
            cardConfig.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
            return false
        }

        // Proximity histogram cards can fetch raw events for shorter periods of time
        if (cardConfig.appearance === DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN) {
            return DashboardService.daysBetween(startTime, endTime) < 5;
        }

        // Fetch raw events for state based sensors
        const deviceType = deviceTypes[0]
        if (deviceTypes.length === 1 && (deviceType === "proximity" || deviceType === "contact" || deviceType === "waterDetector" || deviceType === "motion" || deviceType === "deskOccupancy")) {
            return true
        }

        // All time series sensors can fetch raw events for shorter periods of time
        return DashboardService.daysBetween(startTime, endTime) < 5;
    }

    // Private
    // Returns the desired window size for aggregated data for the given duration
    getWindowSizeSeconds(startTime, endTime, deviceTypes) { // eslint-disable-line class-methods-use-this
        // The backend has support for the following bucket sizes:
        // * 3600s (1 hour)
        // * 7200s (2 hours)
        // * 10800s (3 hours)
        // * 14400s (4 hours)
        // * 21600s (6 hours)
        // * 28800s (8 hours)
        // * 43200s (12 hours)
        // * 86400s (1 day)
        // * 172800s (2 days)
        // * 604800s (1 week)

        const days = DashboardService.daysBetween(startTime, endTime);
        if (deviceTypes.includes('deskOccupancy')) {
            return days <= 5 ? 3600 : 86400
        } 

        if (days <= 14)  { return 3600 * 1 }   // 2 weeks  => 1 hour
        if (days <= 45)  { return 3600 * 2 }   // 45 days  => 2 hours
        if (days <= 90)  { return 3600 * 6 }   // 3 months => 6 hours
        if (days <= 365) { return 3600 * 12 }  // 1 year   => 12 hours
        if (days <= 730) { return 3600 * 24 }  // 2 years  => 1 day
        return 3600 * 48                       // 3 years  => 2 days
    }

    // Private
    // Fetches events for touch, temp, humidity, prox count, and touch count if includeEvents is true
    // and wantsRawData is false. Does not fetch events prox and water (we'll get it from the history API instead).
    // Fetches stats only if there is a single temperature device, and includeStats is true.
    static createAggregateRequestPayload(deviceNames, deviceTypes, cardConfig, startTime, endTime, wantsRawData, windowSizeSeconds, timeLocation) {
        const includeStats = cardConfig.deviceConfig.includeStats
        const includeEvents = cardConfig.deviceConfig.includeEvents

        const aggregationQuery = {
            devices: deviceNames,
            startTime: startTime.format(),
            endTime: endTime.format(),
            fields: [],
            timeLocation
        };

        const windowSize = `${windowSizeSeconds}s`;

        // Defined here because because JS switch-cases are not scoped
        let fieldQuery;

        // Multi-sensor cards can currently either be filled with temperature or humidity data 
        if (deviceTypes.length > 1 || cardConfig.appearance === DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE || cardConfig.appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY) {
            aggregationQuery.timeWindow = windowSize;
            // The "temperature.value" field will be populated if the "humidity.temperature" field is included.
            aggregationQuery.fields = [
                {
                    "fieldName": "temperature.value",
                    "type"     : "AVERAGE",
                },
                {
                    "fieldName": "humidity.temperature",
                    "type"     : "AVERAGE",
                }
            ]
            if (cardConfig.appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY) { 
                aggregationQuery.fields.push({
                    "fieldName": "humidity.relativeHumidity",
                    "type"     : "AVERAGE",
                })
            }
            return aggregationQuery
        }

        // Single device type card, create one or more field queries depending on the device type.
        switch (deviceTypes[0]) {
            case "touch":
                if (includeEvents && wantsRawData === false) {
                    aggregationQuery.timeWindow = windowSize;
                    aggregationQuery.fields.push({
                        "fieldName": "touch",
                        "type"     : "COUNT",
                    });
                }
                break;
            case "temperature":
                fieldQuery = { "fieldName": "temperature.value" };
                if (includeEvents && wantsRawData === false) {
                    fieldQuery.type = "AVERAGE";
                    aggregationQuery.timeWindow = windowSize;
                }
                if (includeStats && deviceNames.length === 1) {
                    fieldQuery.stats = ["AVERAGE", "MIN", "MAX"];
                }

                // Only append the new field if we're either requesting aggregated values or stats
                if (fieldQuery.stats || fieldQuery.type) {
                    aggregationQuery.fields.push(fieldQuery);
                }
                break;
            case "humidity":
                if (includeEvents && wantsRawData === false) {
                    aggregationQuery.timeWindow = windowSize;
                    aggregationQuery.fields.push({ 
                        "fieldName": "humidity.temperature",
                        "type"     : "AVERAGE",
                    });
                    aggregationQuery.fields.push({
                        "fieldName": "humidity.relativeHumidity",
                        "type"     : "AVERAGE",
                    });
                }
                if (includeStats && deviceNames.length === 1) {
                    aggregationQuery.fields.push({ 
                        "fieldName": "humidity.temperature",
                        "stats"    : ["AVERAGE", "MIN", "MAX"]
                    })
                    aggregationQuery.fields.push({ 
                        "fieldName": "humidity.relativeHumidity",
                        "stats"    : ["AVERAGE", "MIN", "MAX"]
                    })   
                }
                break;
            case "proximityCounter":
                if (includeEvents && wantsRawData === false) {
                    aggregationQuery.timeWindow = windowSize;
                    aggregationQuery.fields.push({
                        "fieldName": "objectPresentCount.total",
                        "type"     : "MAX",
                    });
                }
                break;
            case "touchCounter":
                if (includeEvents && wantsRawData === false) {
                    aggregationQuery.timeWindow = windowSize;
                    aggregationQuery.fields.push({
                        "fieldName": "touchCount.total",
                        "type"     : "MAX",
                    });
                }
                break;
            case "proximity": 
                if (includeEvents && wantsRawData === false) {
                    aggregationQuery.timeWindow = windowSize;
                    aggregationQuery.fields.push({
                        "fieldName": "objectPresent.state",
                        "type"     : "COUNT",
                        "state"    : "NOT_PRESENT"
                    })
                }
                break;
            case "contact":
                if (includeEvents && wantsRawData === false) {
                    aggregationQuery.timeWindow = windowSize;
                    aggregationQuery.fields.push({
                        "fieldName": "contact.state",
                        "type"     : "COUNT",
                        "state"    : "OPEN"
                    })
                }
                break;
            case "waterDetector":
                // Ignoring aggregate events for water sensors for now. Will 
                // fetch them using event history API instead so the graph looks familiar 
                // to users. This might change in the future if we realize this puts
                // too much strain on the backend.
                break;
            case "co2":
                if (wantsRawData === false) {
                    if (cardConfig.appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP) {
                        // Different heatmap displays based on time range
                        const days = DashboardService.daysBetween(startTime, endTime);
                        if (days >= DASHBOARD_AGGREGATED_HEATMAP_DAYS_THRESHOLD) {
                            aggregationQuery.timeWindow = '86400s'
                        } else {
                            aggregationQuery.timeWindow = '3600s'
                        }
                    } else {
                        aggregationQuery.timeWindow = windowSize
                    }
                    aggregationQuery.fields.push({ 
                        "fieldName": "co2.ppm",
                        "type"     : "MAX",
                    })
                }
                break;
            case "deskOccupancy":
                aggregationQuery.timeWindow = windowSize
                aggregationQuery.fields.push({
                    "fieldName": "deskOccupancy.state",
                    "type"     : "DURATION",
                });
                break;
            case "motion":
                // Motion sensors should not be aggregated more than 1 day
                if (windowSizeSeconds > 3600 * 24) {
                    aggregationQuery.timeWindow = '86400s'
                } else {
                    aggregationQuery.timeWindow = windowSize
                }
                if (cardConfig.appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP) {
                    // Different heatmap displays based on time range
                    const days = DashboardService.daysBetween(startTime, endTime);
                    if (days >= DASHBOARD_AGGREGATED_HEATMAP_DAYS_THRESHOLD) {
                        aggregationQuery.timeWindow = '86400s'
                    } else {
                        aggregationQuery.timeWindow = '3600s'
                    }
                    aggregationQuery.fields.push({
                        "fieldName": "networkStatus.signalStrength",
                        "type"     : "COUNT",
                    });    
                }
                aggregationQuery.fields.push({
                    "fieldName": "motion.state",
                    "type"     : "DURATION",
                });
                break;
            default:
                console.error(`Unknown device type: ${deviceTypes[0]}`); // eslint-disable-line no-console
                return null;
        }

        if (aggregationQuery.fields.length === 0) {
            return undefined;
        } 
        return aggregationQuery;
    }

    // Private
    // Converts a value from the aggregate API to a structure that is consistent
    // for the dashboard. The touch events will have a format better aligned with
    // the high-chart format, but the remaining will mirror the history API structure
    // with some omitted fields. Omitted fields are: eventId, event.timestamp, and labels.
    static convertAggregateValueToDeviceEvents(deviceName, aggregateEvent) {
        if (aggregateEvent.values.touch_COUNT) {
            // Expand the touch count to an array of device touch events
            const touchEvents = []
            if (!aggregateEvent.values.touch_COUNT) {
                // No touch events for this bucket
                return touchEvents;
            }
            for (let i = 0; i < aggregateEvent.values.touch_COUNT; i++) {
                touchEvents.push({
                    "targetName": deviceName,
                    "eventType": "touch",
                    "data": {
                        "touch": {
                            "updateTime": aggregateEvent.timeWindow
                        }
                    }
                })
            }
            return touchEvents
        }
        if (aggregateEvent.values["objectPresent.state_COUNT"]) {
            return {
                "targetName": deviceName,
                "eventType": "proximity",
                "data": {
                    "objectPresent": {
                        "openCount": aggregateEvent.values["objectPresent.state_COUNT"],
                        "updateTime": aggregateEvent.timeWindow
                    }
                }
            } 
        }
        if (aggregateEvent.values["contact.state_COUNT"]) {
            return {
                "targetName": deviceName,
                "eventType": "contact",
                "data": {
                    "contact": {
                        "openCount": aggregateEvent.values["contact.state_COUNT"],
                        "updateTime": aggregateEvent.timeWindow
                    }
                }
            } 
        }
        if (!isNaN(aggregateEvent.values["temperature.value_AVERAGE"]) && isNaN(aggregateEvent.values["humidity.relativeHumidity_AVERAGE"])) {
            return {
                "targetName": deviceName,
                "eventType": "temperature",
                "data": {
                    "temperature": {
                        "value": aggregateEvent.values["temperature.value_AVERAGE"] || aggregateEvent.values["humidity.temperature_AVERAGE"],
                        "updateTime": aggregateEvent.timeWindow
                    }
                }
            }
        }
        if (!isNaN(aggregateEvent.values["humidity.temperature_AVERAGE"]) && !isNaN(aggregateEvent.values["humidity.relativeHumidity_AVERAGE"])) {
            return {
                "targetName": deviceName,
                "eventType": "humidity",
                "data": {
                    "humidity": {
                        "temperature": aggregateEvent.values["humidity.temperature_AVERAGE"],
                        "relativeHumidity": aggregateEvent.values["humidity.relativeHumidity_AVERAGE"],
                        "updateTime": aggregateEvent.timeWindow
                    }
                }
            }
        }
        if (aggregateEvent.values["objectPresentCount.total_MAX"]) {
            return {
                "targetName": deviceName,
                "eventType": "objectPresentCount",
                "data": {
                    "objectPresentCount": {
                        "total": aggregateEvent.values["objectPresentCount.total_MAX"],
                        "updateTime": aggregateEvent.timeWindow
                    }
                }
            }
        }
        if (aggregateEvent.values["touchCount.total_MAX"]) {
            return {
                "targetName": deviceName,
                "eventType": "touchCount",
                "data": {
                    "touchCount": {
                        "total": aggregateEvent.values["touchCount.total_MAX"],
                        "updateTime": aggregateEvent.timeWindow
                    }
                }
            }
        }
        if (aggregateEvent.values["co2.ppm_MAX"]) {
            return {
                "targetName": deviceName,
                "eventType": "co2",
                "data": {
                    "co2": {
                        "ppm": aggregateEvent.values["co2.ppm_MAX"],
                        "updateTime": aggregateEvent.timeWindow
                    }
                }
            }
        }
        if (aggregateEvent.values["deskOccupancy.state_DURATION"]) {
            // Imaginary event type that plays nicely with existing logic
            return {
                "targetName": deviceName,
                "eventType": "deskOccupancy",
                "data": {
                    "deskOccupancy": {
                        "duration": aggregateEvent.values["deskOccupancy.state_DURATION"],
                        "updateTime": aggregateEvent.timeWindow
                    }
                }
            }
        }
        if (aggregateEvent.values["motion.state_DURATION"]) {
            // Imaginary event type that plays nicely with existing logic
            return {
                "targetName": deviceName,
                "eventType": "motion",
                "data": {
                    "motion": {
                        "duration": aggregateEvent.values["motion.state_DURATION"],
                        "updateTime": aggregateEvent.timeWindow
                    }
                }
            }
        }

        return undefined;
    }
}