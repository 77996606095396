import moment from 'moment';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import { parseQuery } from 'services/QueryParser';
import { DEFAULT_TOKEN, getPageSize, persistPageSize } from 'services/PaginationHelper';
import { UPDATE_EMULATOR, UPDATE_PROJECT } from 'services/Permissions';
import { HEALTH_CHECKER_NETWORK_UP_EVENT, HEALTH_CHECKER_PAGE_VISIBLE_EVENT } from 'services/StudioEvents';
import { MAX_CARDS } from 'services/api/DashboardService';
import { getResourceName } from 'services/api/helper';
import { forceRedrawOnIOS, noop, scrollContainerToActiveChild, convertTimePropsToLocal, dateFromXID } from 'services/utils';
import { animateSensorIcon, fixTimestampJitter, NETWORK_STATUS_TIME_WINDOW } from 'services/SensorHelper';
import {
    STATUS_LIVE,
    STATUS_NOT_INITIALIZED,
    TOP_LEVEL_NETWORK_ERROR_STATE_KEY,
    TOP_LEVEL_NETWORK_ERROR_VISIBLE_STATE_KEY
} from 'services/api/ResourceStream';
import { HEARTBEAT_MAX_GAP } from 'services/charting/data-converter';

import claimDevicesImage from '../../../assets/images/claim_devices.png';
import emulateDevicesImage from '../../../assets/images/emulate_devices.png';
import orderDevicesImage from '../../../assets/images/order_devices.png';
import imgCCONv2 from '../../../assets/images/img_support_ccon_v2.png';
import imgClaimBox from '../../../assets/images/img_claim_box.png';

import { PERSISTENCE_DRIVER_SESSION_STORAGE } from '../../../modules/state/StateService';
import { States } from '../../../app.router';

import TransferSensorsPopupController from '../popups/transfer-sensors/controller';
import TransferSensorsPopupTemplate from '../popups/transfer-sensors/template.html';
import IdentifySensorPopupController from '../popups/identify-sensor/controller';
import IdentifySensorPopupTemplate from '../popups/identify-sensor/template.html';
import EditLabelsPopupController from '../popups/edit-labels/controller';
import EditLabelsPopupTemplate from '../popups/edit-labels/template.html';
import ConfigureSensorsPopupController from '../popups/configure-sensors/controller';
import ConfigureSensorsPopupTemplate from '../popups/configure-sensors/template.html';

import ClaimDevicesController from '../../../common/claim-devices/claim-devices.controller';
import ClaimDevicesTemplate from '../../../common/claim-devices/claim-devices.html';

const PAGE_SIZE_SUFFIX = 'devices';
const STATE_ANIMATION_DURATION = 3000; // 3s

const animateStateChange = (thingId) => {
    const sensorStateNode = document.querySelector(`.cell-${thingId}-state`);
    if (sensorStateNode) {
        setTimeout(() => {
            if (sensorStateNode.classList.contains('text-state--updated')) {
                return;
            }
            sensorStateNode.classList.add('text-state--updated');
            setTimeout(() => {
                sensorStateNode.classList.remove('text-state--updated');
            }, STATE_ANIMATION_DURATION);
        }, 0);
    }
};

const rippleOnSensor = (thingId) => {
    const sensorIconNode = document.querySelector(`.cell-${thingId}-icon`);
    if (sensorIconNode) {
        animateSensorIcon(sensorIconNode);
    }
};

/* @ngInject */
export default class SensorsAndConnectorsController {
    constructor(
        EventEmitter,
        SensorService,
        Loader,
        ToastService,
        TranslationService,
        DialogService,
        RoleManager,
        $state,
        $scope,
        $stateParams,
        $timeout,
        $q,
        StateService,
        DashboardService,
        ProjectManager,
        FeatureFlags
    ) {
        this.EventEmitter = EventEmitter;
        this.SensorService = SensorService;
        this.Loader = Loader;
        this.ToastService = ToastService;
        this.TranslationService = TranslationService;
        this.DialogService = DialogService;
        this.RoleManager = RoleManager;
        this.$state = $state;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$timeout = $timeout;
        this.$q = $q;
        this.StateService = StateService;
        this.ProjectManager = ProjectManager;
        this.FeatureFlags = FeatureFlags;
        this.DashboardService = DashboardService;

        this.performHealthCheck = this.performHealthCheck.bind(this);
        this.onUpdateReceived = this.onUpdateReceived.bind(this);
        this.transferSensorsToProject = this.transferSensorsToProject.bind(this);
        this.batchUpdateLabels = this.batchUpdateLabels.bind(this);
    }

    get currentThingId() {
        return this.$stateParams.thingId;
    }

    get isSideNavOpen() {
        return this.$state.current.name.indexOf(`${(States.SENSORS)}.`) === 0; // open if the sub-state active
    }

    get hasNoSensors() {
        const loadedWithoutErrors = !this.isFetching && this.firstFetchCompleted && !this.firstFetchError;
        const noSpecialCases = !this.query && this.currentPageToken === DEFAULT_TOKEN && !this.nextPageToken;
        return loadedWithoutErrors && noSpecialCases && !this.things.length;
    }

    get noSensorsFound() {
        const noPagination = this.currentPageToken === DEFAULT_TOKEN && !this.nextPageToken;
        return !this.isFetching && this.query && !this.things.length && noPagination;
    }

    get canOpenEmulator() {
        // Todo: Change to READ_EMULATOR when permission checks are implemented in the emulator code
        return this.RoleManager.can(UPDATE_EMULATOR);
    }

    // Claiming is allowed if the "billing_manual_claiming" feature flag is enabled, OR if the organization
    // was created after a specific date. It's done this way because most existing 
    // organizations have auto-claiming enabled, and we don't want to show those customers 
    // this new claiming UI.
    get hasClaimingFeature() {
        const hasNewOrg = this.orgCreatedDate && this.orgCreatedDate > new Date("2022-04-22T00:00:00Z")
        return this.FeatureFlags.isActive('billing_manual_claiming') || hasNewOrg;
    }

    get canClaim() {
        return this.RoleManager.can(UPDATE_PROJECT)
    }

    uiOnParamsChanged({ filter, deviceIds }) {
        if (filter || filter === null) {
            this.loadDataByQuery(filter);
        }
        if (deviceIds) {
            this.selectedRef = [...deviceIds];
        }
    }

    selectionChanged({ selected }) {
        this.selectedDeviceIds = selected;
    }

    $onInit() {
        this.pageState = States.SENSORS;

        this.selectedRef = [];
        this.selectedDeviceIds = [...this.selectedRef];

        this.things = [];

        this.query = this.$stateParams.filter || '';
        this.previousQuery = ''; // Allows us to detect when the user has changed the query
        this.orderBy = 'labels.name';
        this.currentPageSize = getPageSize(PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;

        this.firstFetchCompleted = false;
        this.firstFetchError = false;
        this.isFetching = false;

        this.project = this.ProjectManager.currentProject
        this.claimDevicesImage = claimDevicesImage
        this.emulateDevicesImage = emulateDevicesImage
        this.orderDevicesImage = orderDevicesImage
        this.imgCCONv2 = imgCCONv2
        this.imgClaimBox = imgClaimBox

        this.loadList().then(() => this.$timeout(scrollContainerToActiveChild));

        this.$scope.$on(HEALTH_CHECKER_PAGE_VISIBLE_EVENT, this.performHealthCheck);
        this.$scope.$on(HEALTH_CHECKER_NETWORK_UP_EVENT, this.performHealthCheck);

        this.StateService.setItem(TOP_LEVEL_NETWORK_ERROR_VISIBLE_STATE_KEY, true);

        // Setting the org created date once here to prevent doing it many times
        // in the `hasClaimingFeature` getter.
        const orgId = this.ProjectManager.currentProject.organization.split("/")[1]
        if (orgId) {
            this.orgCreatedDate = dateFromXID(orgId)
        }
    }

    $onDestroy() {
        this.unsubscribeFromLiveUpdates();
        this.StateService.removeItem(TOP_LEVEL_NETWORK_ERROR_VISIBLE_STATE_KEY);
    }

    performHealthCheck() {
        this.showWarning = true;
        this.SensorService.isInternetAccessible().then((internetAccessible) => {
            if (internetAccessible) {               
                // Don't reload if a modal is open
                if (document.body.classList.contains('md-dialog-is-showing')) {
                    return;
                }
                this.$state.transitionTo(this.$state.current, {
                    ...this.$stateParams,
                    sensor: null,
                    doNotTrack: true
                }, {
                    reload: States.SENSORS, inherit: false, notify: false
                });
            } else {
                window.location.reload(true);
            }
        });
    }

    thingUpdated({ thing }) {
        const thingRef = this.thingsMap[thing.id];
        if (thingRef) {
            if (thing.id === this.currentThingId && thing.starred !== thingRef.starred) {
                this.thingUpdate = thing;
            }
        }
    }

    loadList() {
        this.Loader.show();
        this.isFetching = true;

        // Reset orderBy when the user has changed the text in the search box.
        // This will let the backend decide the order based on a match score.
        if (this.previousQuery !== this.query) {
            if (this.query.length > 0) {
                // Let the backend decide when the user has entered a search query
                this.orderBy = '';

                // Check if the query is just a type search query
                const isTypeQuery = this.query.startsWith('type:') && !this.query.includes(' ');
                if (isTypeQuery) {
                    this.orderBy = 'labels.name';
                }
            } else if (this.orderBy === '') {
                // Default to labels.name if the user has removed the search query
                this.orderBy = 'labels.name';
            }
        }
        this.previousQuery = this.query;

        return this.SensorService
            .sensors({
                ...parseQuery(this.query),
                orderBy: this.orderBy,
                pageSize: this.currentPageSize,
                pageToken: this.currentPageToken
            })
            .then(({ data, nextPageToken }) => {
                this.Loader.hide();
                this.nextPageToken = nextPageToken;
                this.onListLoaded(data);
            })
            .catch((serverResponse) => {
                if (!this.firstFetchCompleted) {
                    this.firstFetchError = true;
                }
                this.onLoadingError(serverResponse);
            })
            .finally(() => {
                this.isFetching = false;
                this.firstFetchCompleted = true;
                this.Loader.hide();
                forceRedrawOnIOS();
            });
    }

    clearSearchResult() {
        this.search({query: null});
    }

    onListReorder(order) {
        this.orderBy = order;
        this.currentPageToken = DEFAULT_TOKEN;
        this.loadList();
    }

    onLoadingError(serverResponse) {
        this.ToastService.showSimpleTranslated('sensor_list_wasnt_loaded', {
            serverResponse
        });
    }

    generateThingsMap() {
        this.thingsMap = {};
        const thingIds = [];
        this.things.forEach((thing) => {
            this.thingsMap[thing.id] = thing;
            thingIds.push(thing.id);
        });
        this.unsubscribeFromLiveUpdates();
        if (thingIds.length) {
            this.subscribeToLiveUpdates(thingIds);
            // Fetch optional certificate details for all devices in the list
            this.SensorService.calibrationInfo(thingIds).then(( { calibrationInfo } ) => {
                thingIds.forEach(thingId => {
                    const deviceCalibrationInfo = calibrationInfo.find(device => device.deviceId === thingId)
                    this.thingsMap[thingId].isCalibrated = deviceCalibrationInfo.isCalibrated
                    this.thingsMap[thingId].calibrationTime = deviceCalibrationInfo.isCalibrated ? moment(deviceCalibrationInfo.calibrationTime).format('MMM D, YYYY') : null
                });
            }).catch((serverResponse) => {
                console.error(serverResponse) // eslint-disable-line no-console
            })
        }
        this.showWarning = false;
    }

    onListUpdated(newThings) {
        this.things = _uniqBy([...this.things, ...newThings], 'id');
        this.generateThingsMap();
    }

    onListLoaded(things) {
        this.things = things;

        this.generateThingsMap();

        // TILT-SUPPORT
        // Since thing-projection does not yet support prototypeData events for 
        // sensors, we have to improvise...
        // When the list of sensors is loaded, we look through to see if any of
        // them are a `tilt` sensor. If that's the case, we fetch the 
        // past 30 minutes of `prototypeData` events, and update the device with
        // the most recent event.
        // One downside of this is that when the last known events are received,
        // the `lastSeen` property will be overwritten, leading to a "Last Seen"
        // label in Studio that is too old (not by much though).
        this.things.forEach(thing => {
            if (thing.type !== "tilt") return;

            const params = {
                startTime: moment().subtract(30, "minute").format(),
                endTime: moment().format(),
                eventTypes: ["prototypeData"]
            };
            this.SensorService.events(thing.name, params).then( ({ data }) => {
                if (data.length === 0) return;

                const event = convertTimePropsToLocal(data[0], ["data.prototypeData.updateTime"]);
                event.timestamp = fixTimestampJitter(event.timestamp);
                this.onUpdateReceived(event);
                
                this.$scope.$applyAsync();
            })
        })
    }

    unsubscribeFromLiveUpdates() {
        if (this.liveDataSubscription) {
            this.liveDataSubscription.stop();
        }
    }

    subscribeToLiveUpdates(thingIds) {
        this.liveDataSubscription = this.SensorService.subscribeToAllUpdates(
            {
                deviceIds: thingIds
            },
            this.onUpdateReceived,
            {
                onStatusChange: (status) => {
                    if ([STATUS_NOT_INITIALIZED, STATUS_LIVE].includes(status)) {
                        if (status === STATUS_NOT_INITIALIZED) {
                            this.StateService.setItem(TOP_LEVEL_NETWORK_ERROR_STATE_KEY, true, {
                                persistenceDriver: PERSISTENCE_DRIVER_SESSION_STORAGE
                            });
                        } else {
                            this.StateService.removeItem(TOP_LEVEL_NETWORK_ERROR_STATE_KEY);
                        }
                        this.$scope.$applyAsync();
                    }
                }
            }
        );
    }

    transferSensorsToProject(projectId, sensors) {
        this.Loader.show();
        return this.SensorService
            .transferSensors(getResourceName(['projects', projectId]), {
                devices: sensors.map(sensor => sensor.name)
            })
            .then((data) => {
                const { transferredDevices, transferErrors } = data;

                if (!transferredDevices.length) {
                    return this.$q.reject();
                }

                const transferredIds = transferredDevices.map(name => name.split('/').pop());
                this.things = this.things.filter(thing => !transferredIds.includes(thing.id));
                this.selectedRef = this.selectedRef.filter(thingId => !transferredIds.includes(thingId));
                transferredIds.forEach((id) => {
                    delete this.thingsMap[id];
                });

                if (!transferErrors.length) {
                    this.ToastService.showSimpleTranslated('sensor_transfer_was_completed', {}, {
                        transferredCount: transferredDevices.length
                    });
                } else {
                    this.ToastService.showSimpleTranslated('sensor_transfer_was_partially_completed', {}, {
                        failedCount: transferErrors.length,
                        totalCount: transferredDevices.length + transferErrors.length
                    });
                }

                return data;
            })
            .catch((error) => {
                if (error.status === 400) {
                    this.ToastService.showSimpleTranslated('sensor_transfer_wasnt_completed', {
                        serverResponse: error
                    });
                } else {
                    this.ToastService.showSimpleTranslated('sensor_transfer_wasnt_completed');
                }
            })
            .finally(() => {
                this.Loader.hide();
            });
    }

    showTransferSensorsPopup({ sensors: deviceIds, event }) {
        return this.SensorService
            .getFromCache(deviceIds)
            .then(({devices}) => this.DialogService.show({
                controller: TransferSensorsPopupController,
                controllerAs: '$ctrl',
                template: TransferSensorsPopupTemplate,
                parent: document.body,
                targetEvent: event,
                openFrom: 'top',
                closeTo: 'top',
                clickOutsideToClose: true,
                escapeToClose: true,
                fullscreen: true,
                locals: {
                    sensors: devices,
                    transferSensorsToProject: this.transferSensorsToProject
                }
            }).catch(noop));
    }

    showEditLabelsPopup({ sensors: deviceIds, event }) {
        return this.SensorService
            .getFromCache(deviceIds)
            .then(({devices}) => this.DialogService.show({
                controller: EditLabelsPopupController,
                controllerAs: '$ctrl',
                template: EditLabelsPopupTemplate,
                parent: document.body,
                targetEvent: event,
                openFrom: 'top',
                closeTo: 'top',
                clickOutsideToClose: true,
                escapeToClose: true,
                fullscreen: true,
                locals: {
                    sensors: devices,
                    batchUpdateLabels: this.batchUpdateLabels
                }
            }).catch(noop));
    }

    showConfigurePopup({ sensors: deviceIds }) {
        return this.SensorService
            .getFromCache(deviceIds)
            .then(({devices}) => this.DialogService.show({
                controller: ConfigureSensorsPopupController,
                controllerAs: '$ctrl',
                template: ConfigureSensorsPopupTemplate,
                parent: document.body,
                targetEvent: event,
                openFrom: 'top',
                closeTo: 'top',
                clickOutsideToClose: true,
                escapeToClose: true,
                fullscreen: true,
                locals: {
                    sensors: devices,
                    batchUpdateLabels: this.batchUpdateLabels
                }
            }).catch(noop));
    }

    addSensorsToDashboard({ sensors: deviceIds }) {

        // Cards added will be 1 x 1 in size and placed in the first available position
        let promise = this.DashboardService.getDashboardConfig(this.ProjectManager.currentProjectId).then(dashboard => {
            // Limit the number of how many cards can be added to the dashboard. Presents a toast
            // if adding the selected sensors to the dashboard would exceed the specified limit
            if (deviceIds.length + dashboard.cards.length > MAX_CARDS) {
                this.ToastService.showSimpleTranslated('dashboard_too_many_cards', {}, {
                    limit: MAX_CARDS,
                    count: deviceIds.length + dashboard.cards.length
                });
                return;
            }

            for (let i = 0; i < deviceIds.length; i++) {
                dashboard.cards.push({cols: 1, rows: 1, y: 0, x: 0, 
                    card_type: "DEVICE",
                    deviceConfig: {
                        deviceIds: [deviceIds[i]],
                        includeStats: true,
                        includeEvents: true
                    }
                });
            }

            promise = this.DashboardService.updateDashboardConfig(this.ProjectManager.currentProjectId, dashboard).then(() => {
                this.ToastService.showSimpleTranslated('dashboard_devices_was_added');
            }).catch(error => {
                console.error(error); // eslint-disable-line no-console
                this.ToastService.showSimpleTranslated('dashboard_devices_wasnt_added');
            });
        });
        this.Loader.promise = promise;
    }

    batchUpdateLabels(data) {
        this.Loader.show();
        return this.SensorService
            .batchUpdate(data)
            .then((response) => {
                if (response && response.batchErrors && response.batchErrors.length) {
                    this.ToastService.showSimpleTranslated('sensor_batch_was_partially_updated');
                } else {
                    this.ToastService.showSimpleTranslated('sensor_batch_was_updated');
                }
            })
            .catch((e) => {
                this.ToastService.showSimpleTranslated('sensor_batch_wasnt_updated');
                throw e;
            })
            .finally(() => {
                this.Loader.hide();
            });
    }

    showIdentifySensorPopup(event) {
        this.DialogService.show({
            controller: IdentifySensorPopupController,
            controllerAs: '$ctrl',
            template: IdentifySensorPopupTemplate,
            parent: document.body,
            targetEvent: event,
            openFrom: 'top',
            closeTo: 'top',
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
                searchTerm: this.query
            }
        });
    }
    
    showClaimDeviceModal() {
        this.DialogService.show({
            controller: ClaimDevicesController,
            controllerAs: '$ctrl',
            template: ClaimDevicesTemplate,
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
                refreshDeviceList: this.loadList.bind(this)
            }
        });
    }

    onUpdateReceived(event) {
        const thingId = event.targetName.split('/').pop();

        const thing = this.thingsMap[thingId];
        if (!thing) {
            return;
        }

        const lastUpdateTime = _get(thing, 'reported.networkStatus.updateTime', null)
        if (lastUpdateTime && moment(event.timestamp).diff(lastUpdateTime, 'seconds') < NETWORK_STATUS_TIME_WINDOW * -1) {
            return // Backfill event, don't set it to be the latest value
        }

        if (event.eventType === 'labelsChanged') {
            // Remove any labels that were removed, and update labels that were modified.
            thing.labels = Object.keys(thing.labels)
                .filter(key => event.data.removed.indexOf(key) < 0)
                .reduce((acc, key) => {
                    acc[key] = Object.prototype.hasOwnProperty.call(event.data.modified, key)
                        ? event.data.modified[key]
                        : thing.labels[key];
                    return acc;
                }, {});

            // Add labels that were added
            Object.keys(event.data.added).forEach((key) => {
                thing.labels[key] = event.data.added[key];
            });

            // If we're sorting by the name label, and the name label of one of the devices changed, we want
            // to reload the device list to make sure our ordering is correct.
            // NOTE: This automatic re-sorting only works when the device that got a new name is on the current page.
            const nameLabelChanged = (event.data.modified.name || event.data.added.name || event.data.removed.name);
            const isOrderingByName = this.orderBy.includes('labels.name');
            if (nameLabelChanged && isOrderingByName) {
                this.loadList();
            }
        } else {
            const eventType = event.eventType;

            // Animate the device on touch
            if (eventType === 'touch') {
                rippleOnSensor(thing.id);
            }

            if (eventType === 'networkStatus') {
                // Special handling for network status events to make sure the
                // CCON with the best signal strength determines the overall
                // signal strength of the sensor.
                SensorsAndConnectorsController.updateNetworkStatus(thing, event);

                // If the user is sorting on "last seen" or "signal", we want to reload the list
                // to make sure the list the user is looking at is still sorted.
                // NOTE: This automatic re-sorting only works if the device that got a new networkStatus
                // is on the current page.
                const isOrderedByLastSeen = this.orderBy.includes('reported.networkStatus.updateTime');
                const isOrderedBySignal = this.orderBy.includes('reported.networkStatus.signalStrength');
                if (isOrderedByLastSeen || isOrderedBySignal) {
                    this.loadList();
                }
            } else {
                // Not networkStatus event, so just override the existing event.
                thing.reported = {
                    ...thing.reported,
                    [eventType]: event.data[eventType]
                };
            }

            if (thing.type !== 'ccon') {
                thing.lastSeen = event.timestamp;
            }

            if (eventType === thing.type || (eventType === 'objectPresent' && thing.type === 'proximity')) {
                animateStateChange(thing.id);
            }
        }

        this.$scope.$applyAsync();
    }

    static updateNetworkStatus(thing, newEvent) {

        const lastUpdateTime = _get(thing, 'reported.networkStatus.updateTime', null)
        if (lastUpdateTime && moment(newEvent.timestamp).diff(lastUpdateTime, 'seconds') < NETWORK_STATUS_TIME_WINDOW * -1) {
            return // Backfill event, don't set it to be the latest value
        }

        const eventType = "networkStatus";

        // Grab the existing list of CCONs for this sensor.
        let ccons = [...thing.reported[eventType].cloudConnectors];

        // Overwrite the networkStatus event for this sensor to get the updated 
        // updateTime and transmissionMode, etc. Will replace the ccons, signal
        // strength, and RSSI.
        thing.reported[eventType] = newEvent.data[eventType];

        // Extract fields from the event, it includes one and only one CCON.
        const networkStatus = newEvent.data[eventType];
        const newCCON = networkStatus.cloudConnectors[0];

        // Inject a timestamp for the CCON. Will be used to purge CCONs from
        // older heartbeats (CCONs that may have gotten out of range).
        newCCON.timestamp = +new Date(networkStatus.updateTime);

        // Update the ccons list with the new event.
        const cconIndex = ccons.findIndex(ccon => 
            ccon.id === newCCON.id
        );
        if (cconIndex > -1) {
            // Already know about this CCON, override it.
            ccons[cconIndex] = newCCON;
        } else {
            // New CCON for this sensor, add it to the list.
            ccons.push(newCCON);
        }

        // Remove any CCONs that are from a previous heartbeat (timestamp is too old, or
        // they don't have a timestamp).
        ccons = ccons.filter(ccon => {
            // Exclude CCONs that don't have a timestamp. These are the initial CCONs
            // from when the device list was loaded initially.
            if (!ccon.timestamp) {
                return false;
            }

            // If the timestamp is older than the maximum expected heartbeat interval, 
            // we consider it to be part of a previous heartbeat and should be discarded.
            if (new Date() - ccon.timestamp > HEARTBEAT_MAX_GAP) {
                return false;
            }

            return true;
        });
        
        if (ccons.length > 0) {
            // Find the CCON with the best (highest) RSSI, and use that for the networkStatus event
            const bestCCON = ccons.reduce((accumulator, ccon) => 
                accumulator.rssi > ccon.rssi ? accumulator : ccon
            );
            thing.reported[eventType].signalStrength = bestCCON.signalStrength;
            thing.reported[eventType].rssi = bestCCON.rssi;
            thing.reported[eventType].cloudConnectors = ccons;
        }
    }

    setPageToken({ pageToken }) {
        this.currentPageToken = pageToken;
        this.loadList();
    }

    setPageSize({ pageSize }) {
        persistPageSize(pageSize, PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.currentPageSize = pageSize;
        this.loadList();
    }

    search({ query }) {
        this.$state.go(this.$state.$current.name, {
            filter: query
        });
    }

    loadDataByQuery(query) {
        this.query = query || '';
        this.currentPageToken = DEFAULT_TOKEN;
        this.loadList();
    }
}
