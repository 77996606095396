import controller from './accuracy-validation.controller';

const template = require('./accuracy-validation.html');

export default {
    bindings: {
        device: '<',
        validation: '<',
        eventsObservable: '<',
        onCancelValidation: '&',
    },
    template,
    controller
};
