import angular from 'angular';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _set from 'lodash/set';
import moment from 'moment/moment';
import Config from 'services/config/local';

/**
 * Utils class, helper functions
 */
export default class Utils {
    /**
     * Replace %key% to value from parameters in given string value
     * @param {Object} parameters - Object with key:value
     * @param {string} value - String value for processing
     * @returns {string} parsed value
     * @static
     */
    static formatString(parameters, value) {
        let result = value;

        Object.keys(parameters).forEach(parameter => {
            result = result.split(`%${parameter}%`).join(parameters[parameter]);
        });

        return result;
    }

    /**
     * Remove keys from obj
     * @param {Object} obj
     * @param {Array} keys - Array of key names
     * @returns {Object} If keys is empty original obj is returned, otherwise new object with removed key returned
     * @static
     */
    static removeKeysFromObject(obj, keys) {
        if (!keys.length) return obj;

        const tempObj = _cloneDeep(obj);

        keys.forEach(key => {
            if (key in tempObj) {
                delete tempObj[key];
            }
        });

        return tempObj;
    }

    static buildApiUrl(key, params = {}) {
        const [section, type] = key.split('.');
        const url = `${Config.api.host}${Config.api.version}${Config.api[section][type]}`;
        return Utils.formatString(params, url);
    }

    static compareByKey(key) {
        return (a, b) => {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            return 0;
        };
    }

    static buildSearchIndex(data, config = {}) {
        const index = [];
        const idKey = config.id || 'id';
        const displayNameKey = config.displayName || 'name';
        data.forEach(item => {
            index.push({
                id: item[idKey],
                name: item[displayNameKey],
                searchableName: item[displayNameKey].toLowerCase()
            });
        });
        return index.sort(Utils.compareByKey('searchableName'));
    }

    static searchInIndex(index, query, provideEverything = false) {
        const searchableQuery = query.toLowerCase();

        if (!query && provideEverything) {
            return index;
        }

        return index.filter(
            item => item.searchableName.indexOf(searchableQuery) >= 0
        );
    }

    static httpExtractData(response) {
        return response.data;
    }
}

export const getPropByKey = (obj, prop) =>
    prop.split('.').reduce((acc, key) => acc[key], obj);

const FORCE_REDRAW_DELAY = 300; // in milliseconds

export const forceRedrawOnIOS = () => {
    const iOS = !!navigator.userAgent.match(/iP(ad|od|hone)/i);

    if (!iOS) {
        return;
    }

    setTimeout(() => {
        const node = document.querySelector('dt-layout-content');
        if (node) {
            node.style.display = 'none';
            node.offsetHeight; // eslint-disable-line no-unused-expressions
            node.style.display = '';
        }
    }, FORCE_REDRAW_DELAY);
};

export const noop = () => {};

export const truncateString = (str, num) => {
    if (str.length > num) {
        return `${str.slice(0, num)}...`;
    } 
    return str;
}

export const getExistingTimeProps = (obj, timeProps) =>
    timeProps
        .map(prop => [prop, _get(obj, prop, null)])
        .filter(([, value]) => value);

export const convertTimePropsToLocal = (obj, timeProps) => {
    getExistingTimeProps(obj, timeProps).forEach(([prop, value]) => {
        _set(obj, prop, moment(value).toISOString(true));
    });
    return obj;
};

export const convertToSearchResults = ({ data }) =>
    data.map(item => ({
        id: item.id,
        name: item.displayName
    }));

export const hasOwnProperty = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);

export const scrollContainerToActiveChild = (
    containerSelector = 'md-table-container',
    activeChildSelector = '.md-row-active'
) => {
    const container = document.querySelector(containerSelector);
    if (!container) {
        return;
    }

    const activeChild = container.querySelector(activeChildSelector);
    if (!activeChild) {
        return;
    }

    const containerHeight = container.clientHeight;

    const visibleTop = container.scrollTop;
    const visibleBottom = visibleTop + containerHeight;

    const elementTop = activeChild.offsetTop;
    const elementCenter = elementTop + activeChild.clientHeight / 2;

    const visible =
        elementCenter >= visibleTop && elementCenter <= visibleBottom;

    if (!visible) {
        container.scrollTop = elementCenter - containerHeight / 2;
    }
};

export const LAYOUT_MOBILE_BREAKPOINT = 736;


export const isMobileLayout = () =>
    window.innerWidth < LAYOUT_MOBILE_BREAKPOINT;

export const isTabletLayout = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    return isTablet;
}

export const scrollNodeToTop = selector => {
    const node = document.querySelector(selector);
    if (node) {
        node.scrollTop = 0;
    }
};

export const injectIdProp = resource => {
    if (resource.name) {
        Object.defineProperty(resource, 'id', {
            value: resource.name.split('/').pop()
        });
    }
    return resource;
};

export const applyInjectors = (entity, injectors) =>
    injectors.reduce((acc, injector) => injector(acc), entity);

export const cloneEntity = entity =>
    applyInjectors(_cloneDeep(entity), [injectIdProp]);

export const getEntityProject = entityName =>
    entityName
        .split('/')
        .slice(0, 2)
        .join('/');

export const selectNodeContents = node => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
};

export const getValueFromStore = (store, key, defaultValue = null) => {
    let result = store.getItem(key);
    if (result !== null) {
        try {
            result = JSON.parse(result);
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
        }
        return result;
    }
    return defaultValue;
};

export const setValueToStore = (store, key, value) => {
    store.setItem(key, JSON.stringify(value));
};

export const removeItemFromStore = (store, key) => {
    store.removeItem(key);
};

let UserPreferencesManager;

export const getUserPreferencesManager = () => {
    if (!UserPreferencesManager) {
        UserPreferencesManager = angular
            .element(document.body)
            .injector()
            .get('UserPreferencesManager');
    }
    return UserPreferencesManager;
};

export const getTemperatureUnitSuffix = () =>
    getUserPreferencesManager().useFahrenheit ? '°F' : '°C';

export const use24HourClock = () => getUserPreferencesManager().use24HourClock;

export const getHoursMinutesFormat = () =>
    getUserPreferencesManager().use24HourClock ? 'HH:mm' : 'h:mm A';

export const getHoursMinutesSecondsFormat = () =>
    getUserPreferencesManager().use24HourClock ? 'HH:mm:ss' : 'h:mm:ss A';

export const getHighchartTimeFormat = () => {
    return getUserPreferencesManager().use24HourClock ? '%H:%M' : '%l:%M %p';
}

export const celsiusToFahrenheit = value => (value * 9) / 5 + 32;
export const fahrenheitToCelsius = value => (value - 32) * 5 / 9;

// TILT-SUPPORT
export const accelerationFieldsToGravity = fields => {
    const getAcc = name => fields.find(field => field.name === name).value;

    return {
        x: getAcc("accelerationX"),
        y: getAcc("accelerationY"),
        z: getAcc("accelerationZ")
    };
}

/**
 * Highcharts charts can reflow themselves automatically when their container size changes.
 * However, sometimes it becomes an issue to shrink back.
 *
 * This function is a workaround.
 * It takes all chart instances rendered at the moment and sets their container to be narrow.
 * Charts reflow to accommodate that narrow space.
 * After that containers are set to their natural width.
 * Charts reflow again and fit the container perfectly.
 *
 * Visually it has noticeable blink, it would be very good to improve.
 */
export const reflowCharts = () => {
    if (!window.Highcharts) return;

    window.Highcharts.charts.forEach(chart => {
        const container = chart.renderTo;
        if (container.style.position !== 'absolute') {
            container.style.display = 'block';
            container.style.width = '250px'; // any value that makes container narrow enough
            chart.reflow();
            container.style.width = ''; // spread over available space again
        };

        setTimeout(() => {
            chart.reflow();
        }, 0);
    });
};


// Local storage for the preferred camera used for QR scanning. Stores the ID of the camera.
export const PREFERRED_CAMERA = 'dt-preferred-camera';

// ISO 3166 Alpha-2 code
export const ISO3166Countries = () => {
    return {
        'AF' : 'Afghanistan',
        'AX' : 'Aland Islands',
        'AL' : 'Albania',
        'DZ' : 'Algeria',
        'AS' : 'American Samoa',
        'AD' : 'Andorra',
        'AO' : 'Angola',
        'AI' : 'Anguilla',
        'AQ' : 'Antarctica',
        'AG' : 'Antigua And Barbuda',
        'AR' : 'Argentina',
        'AM' : 'Armenia',
        'AW' : 'Aruba',
        'AU' : 'Australia',
        'AT' : 'Austria',
        'AZ' : 'Azerbaijan',
        'BS' : 'Bahamas',
        'BH' : 'Bahrain',
        'BD' : 'Bangladesh',
        'BB' : 'Barbados',
        'BY' : 'Belarus',
        'BE' : 'Belgium',
        'BZ' : 'Belize',
        'BJ' : 'Benin',
        'BM' : 'Bermuda',
        'BT' : 'Bhutan',
        'BO' : 'Bolivia',
        'BA' : 'Bosnia And Herzegovina',
        'BW' : 'Botswana',
        'BV' : 'Bouvet Island',
        'BR' : 'Brazil',
        'IO' : 'British Indian Ocean Territory',
        'BN' : 'Brunei Darussalam',
        'BG' : 'Bulgaria',
        'BF' : 'Burkina Faso',
        'BI' : 'Burundi',
        'KH' : 'Cambodia',
        'CM' : 'Cameroon',
        'CA' : 'Canada',
        'CV' : 'Cape Verde',
        'KY' : 'Cayman Islands',
        'CF' : 'Central African Republic',
        'TD' : 'Chad',
        'CL' : 'Chile',
        'CN' : 'China',
        'CX' : 'Christmas Island',
        'CC' : 'Cocos (Keeling) Islands',
        'CO' : 'Colombia',
        'KM' : 'Comoros',
        'CG' : 'Congo',
        'CD' : 'Congo, Democratic Republic',
        'CK' : 'Cook Islands',
        'CR' : 'Costa Rica',
        'CI' : 'Cote D\'Ivoire',
        'HR' : 'Croatia',
        'CU' : 'Cuba',
        'CY' : 'Cyprus',
        'CZ' : 'Czech Republic',
        'DK' : 'Denmark',
        'DJ' : 'Djibouti',
        'DM' : 'Dominica',
        'DO' : 'Dominican Republic',
        'EC' : 'Ecuador',
        'EG' : 'Egypt',
        'SV' : 'El Salvador',
        'GQ' : 'Equatorial Guinea',
        'ER' : 'Eritrea',
        'EE' : 'Estonia',
        'ET' : 'Ethiopia',
        'FK' : 'Falkland Islands (Malvinas)',
        'FO' : 'Faroe Islands',
        'FJ' : 'Fiji',
        'FI' : 'Finland',
        'FR' : 'France',
        'GF' : 'French Guiana',
        'PF' : 'French Polynesia',
        'TF' : 'French Southern Territories',
        'GA' : 'Gabon',
        'GM' : 'Gambia',
        'GE' : 'Georgia',
        'DE' : 'Germany',
        'GH' : 'Ghana',
        'GI' : 'Gibraltar',
        'GR' : 'Greece',
        'GL' : 'Greenland',
        'GD' : 'Grenada',
        'GP' : 'Guadeloupe',
        'GU' : 'Guam',
        'GT' : 'Guatemala',
        'GG' : 'Guernsey',
        'GN' : 'Guinea',
        'GW' : 'Guinea-Bissau',
        'GY' : 'Guyana',
        'HT' : 'Haiti',
        'HM' : 'Heard Island & Mcdonald Islands',
        'VA' : 'Holy See (Vatican City State)',
        'HN' : 'Honduras',
        'HK' : 'Hong Kong',
        'HU' : 'Hungary',
        'IS' : 'Iceland',
        'IN' : 'India',
        'ID' : 'Indonesia',
        'IR' : 'Iran, Islamic Republic Of',
        'IQ' : 'Iraq',
        'IE' : 'Ireland',
        'IM' : 'Isle Of Man',
        'IL' : 'Israel',
        'IT' : 'Italy',
        'JM' : 'Jamaica',
        'JP' : 'Japan',
        'JE' : 'Jersey',
        'JO' : 'Jordan',
        'KZ' : 'Kazakhstan',
        'KE' : 'Kenya',
        'KI' : 'Kiribati',
        'KR' : 'Korea',
        'KW' : 'Kuwait',
        'KG' : 'Kyrgyzstan',
        'LA' : 'Lao People\'s Democratic Republic',
        'LV' : 'Latvia',
        'LB' : 'Lebanon',
        'LS' : 'Lesotho',
        'LR' : 'Liberia',
        'LY' : 'Libyan Arab Jamahiriya',
        'LI' : 'Liechtenstein',
        'LT' : 'Lithuania',
        'LU' : 'Luxembourg',
        'MO' : 'Macao',
        'MK' : 'Macedonia',
        'MG' : 'Madagascar',
        'MW' : 'Malawi',
        'MY' : 'Malaysia',
        'MV' : 'Maldives',
        'ML' : 'Mali',
        'MT' : 'Malta',
        'MH' : 'Marshall Islands',
        'MQ' : 'Martinique',
        'MR' : 'Mauritania',
        'MU' : 'Mauritius',
        'YT' : 'Mayotte',
        'MX' : 'Mexico',
        'FM' : 'Micronesia, Federated States Of',
        'MD' : 'Moldova',
        'MC' : 'Monaco',
        'MN' : 'Mongolia',
        'ME' : 'Montenegro',
        'MS' : 'Montserrat',
        'MA' : 'Morocco',
        'MZ' : 'Mozambique',
        'MM' : 'Myanmar',
        'NA' : 'Namibia',
        'NR' : 'Nauru',
        'NP' : 'Nepal',
        'NL' : 'Netherlands',
        'NC' : 'New Caledonia',
        'NZ' : 'New Zealand',
        'NI' : 'Nicaragua',
        'NE' : 'Niger',
        'NG' : 'Nigeria',
        'NU' : 'Niue',
        'NF' : 'Norfolk Island',
        'MP' : 'Northern Mariana Islands',
        'NO' : 'Norway',
        'OM' : 'Oman',
        'PK' : 'Pakistan',
        'PW' : 'Palau',
        'PS' : 'Palestinian Territory, Occupied',
        'PA' : 'Panama',
        'PG' : 'Papua New Guinea',
        'PY' : 'Paraguay',
        'PE' : 'Peru',
        'PH' : 'Philippines',
        'PN' : 'Pitcairn',
        'PL' : 'Poland',
        'PT' : 'Portugal',
        'QA' : 'Qatar',
        'RE' : 'Reunion',
        'RO' : 'Romania',
        'RU' : 'Russian Federation',
        'RW' : 'Rwanda',
        'BL' : 'Saint Barthelemy',
        'KN' : 'Saint Kitts And Nevis',
        'LC' : 'Saint Lucia',
        'MF' : 'Saint Martin',
        'PM' : 'Saint Pierre And Miquelon',
        'VC' : 'Saint Vincent And Grenadines',
        'WS' : 'Samoa',
        'SM' : 'San Marino',
        'ST' : 'Sao Tome And Principe',
        'SA' : 'Saudi Arabia',
        'SN' : 'Senegal',
        'RS' : 'Serbia',
        'SC' : 'Seychelles',
        'SL' : 'Sierra Leone',
        'SG' : 'Singapore',
        'SK' : 'Slovakia',
        'SI' : 'Slovenia',
        'SB' : 'Solomon Islands',
        'SO' : 'Somalia',
        'ZA' : 'South Africa',
        'GS' : 'South Georgia And Sandwich Isl.',
        'ES' : 'Spain',
        'LK' : 'Sri Lanka',
        'SD' : 'Sudan',
        'SR' : 'Suriname',
        'SJ' : 'Svalbard And Jan Mayen',
        'SZ' : 'Swaziland',
        'SE' : 'Sweden',
        'CH' : 'Switzerland',
        'SY' : 'Syrian Arab Republic',
        'TW' : 'Taiwan',
        'TJ' : 'Tajikistan',
        'TZ' : 'Tanzania',
        'TH' : 'Thailand',
        'TL' : 'Timor-Leste',
        'TG' : 'Togo',
        'TK' : 'Tokelau',
        'TO' : 'Tonga',
        'TT' : 'Trinidad And Tobago',
        'TN' : 'Tunisia',
        'TR' : 'Turkey',
        'TM' : 'Turkmenistan',
        'TC' : 'Turks And Caicos Islands',
        'TV' : 'Tuvalu',
        'UG' : 'Uganda',
        'UA' : 'Ukraine',
        'AE' : 'United Arab Emirates',
        'GB' : 'United Kingdom',
        'US' : 'United States',
        'UM' : 'United States Outlying Islands',
        'UY' : 'Uruguay',
        'UZ' : 'Uzbekistan',
        'VU' : 'Vanuatu',
        'VE' : 'Venezuela',
        'VN' : 'Viet Nam',
        'VG' : 'Virgin Islands, British',
        'VI' : 'Virgin Islands, U.S.',
        'WF' : 'Wallis And Futuna',
        'EH' : 'Western Sahara',
        'YE' : 'Yemen',
        'ZM' : 'Zambia',
        'ZW' : 'Zimbabwe'
    }
}

// ISO 3166-2:US
export const ISO3166States = () => {

    return {
        "AL": "Alabama",
        "AK": "Alaska",
        "AS": "American Samoa",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "DC": "District Of Columbia",
        "FM": "Federated States Of Micronesia",
        "FL": "Florida",
        "GA": "Georgia",
        "GU": "Guam",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MH": "Marshall Islands",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "MP": "Northern Mariana Islands",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PW": "Palau",
        "PA": "Pennsylvania",
        "PR": "Puerto Rico",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VI": "Virgin Islands",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
    }
}

/* eslint-disable */
// Since no XID libraries are available for the browser that uses the alphabet
// used in https://github.com/rs/xid (it is not Crockford, even though it looks
// like it), we have to implement this ourselves. This implementation is just 
// a straight port of the Go implementation, but just the first 4 bytes which
// contains the embedded timestamp.
export const dateFromXID = xid => {
    // Basic validation
    if (!xid || typeof(xid) !== "string" || xid.length !== 20) {
        return null
    }

    // Prepare the lookup tables
    // Source: https://github.com/rs/xid/blob/66f8c42da230c3323ed4e29805e73eefbad41fc5/id.go#L93
    const encoding = "0123456789abcdefghijklmnopqrstuv";
    const dec = new Uint8Array(256);
    for (const i in dec) {
        dec[i] = 0xff;
    }
    for (const i in encoding) {
        dec[encoding.charCodeAt(i)] = i;
    }

    // Validate that each character is in the known alphabet
    for (const char of xid) {
        if (dec[char.charCodeAt(0)] == 0xff) {
            return null
        }
    }

    // Decode the XID string to an XID byte buffer. We're only decoding the 
    // first 4 bytes which are related to the timestamp embedded in the XID.
    // Source: https://github.com/rs/xid/blob/66f8c42da230c3323ed4e29805e73eefbad41fc5/id.go#L262
    const xidBuffer = new Uint8Array(4);
    xidBuffer[3] = dec[xid.charCodeAt(4)] << 7 | dec[xid.charCodeAt(5)] << 2 | dec[xid.charCodeAt(6)] >> 3;
    xidBuffer[2] = dec[xid.charCodeAt(3)] << 4 | dec[xid.charCodeAt(4)] >> 1;
    xidBuffer[1] = dec[xid.charCodeAt(1)] << 6 | dec[xid.charCodeAt(2)] << 1 | dec[xid.charCodeAt(3)] >> 4;
    xidBuffer[0] = dec[xid.charCodeAt(0)] << 3 | dec[xid.charCodeAt(1)] >> 2;

    // Convert the 4 bytes of the XID buffer to a 32-bit value. This represents
    // the timestamp embedded in the XID in unix time (seconds since 1970).
    const unix = (xidBuffer[0] << 24) + (xidBuffer[1] << 16) + (xidBuffer[2] << 8) + (xidBuffer[3]);
    
    // Return a date object
    return new Date(unix * 1000);
}
/* eslint-enable */


export const parseDuration = duration => {
    if (!duration) return moment.duration(0);
    if (duration.slice(-1) !== 's')
        throw new Error("invalid duration: suffix 's' required");
    const seconds = parseFloat(duration.slice(0, -1));
    return moment.duration(seconds, 'seconds');
}

// Humanize the duration string (e.g 300s) into a more human readable version.
// E.g, "1 hour, 2 minutes and 30 seconds"
export const durationHumanize = durationString => {
    const pluralize = (v, plural) => {
        if (v > 1) return `${plural}s`;
        return plural
    }
    const d = parseDuration(durationString);
    const parts = [];
    const seconds = d.seconds();
    const minutes = d.minutes();
    const hours = d.hours() + (d.days() * 24);
    if (hours > 0) parts.push(`${hours} ${pluralize(hours, "hour")}`);
    if (minutes > 0) parts.push(`${minutes} ${pluralize(minutes, "minute")}`);
    if (seconds > 0) parts.push(`${seconds} ${pluralize(seconds, "second")}`);
    if (parts.length === 0) {
        return "";
    }
    if (parts.length === 1) {
        return parts[0];
    }
    const last = parts.pop();
    return `${parts.join(", ")} and ${last}`;
}

export const ruleFields = {
    temperature: {
        displayName: 'Temperature',
        operator: 'range',
        default: {
            range: {
                type: 'ABOVE'
            }
        }
    },
    co2: {
        displayName: 'CO2',
        operator: 'range',
        unit: 'PPM',
        default: {
            range: {
                type: 'ABOVE'
            }
        }
    },
    relativeHumidity: {
        displayName: 'Humidity',
        operator: 'range',
        unit: '%RH',
        default: {
            range: {
                type: 'ABOVE'
            }
        }
    },
    waterPresent: {
        displayName: 'Water Detected',
        operator: 'present',
        default: {
            presence: 'PRESENT'
        },
        operatorDisplayNames: {
            'PRESENT': 'Water present',
            'NOT_PRESENT': 'No water present'
        }
    },
    contact: {
        displayName: 'Door & Window',
        operator: 'contact',
        default: {
            contact: 'OPEN'
        },
        operatorDisplayNames: {
            'OPEN': 'Open',
            'CLOSED': 'Closed'
        }
    },
    contactCount: {
        displayName: 'Door & Window (Count)',
        operator: 'count',
        default: {
            contact: 'OPEN'
        },
        operatorDisplayNames: {
            'OPEN': 'Open',
            'CLOSED': 'Closed'
        }
    },
    motion: {
        displayName: 'Motion',
        operator: 'motion',
        default: {
            motion: 'MOTION_DETECTED'
        },
        operatorDisplayNames: {
            'MOTION_DETECTED': 'Motion detected',
            'NO_MOTION_DETECTED': 'No motion detected'
        }
    },
    touch: {
        displayName: 'Touched',
    },
    touchCount: {
        displayName: 'Touch (Count)',
        operator: 'count'
    },
    objectPresent: {
        displayName: 'Proximity Status',
        operator: 'present',
        default: {
            presence: 'NOT_PRESENT'
        },
        operatorDisplayNames: {
            'NOT_PRESENT': 'Open (no object present)',
            'PRESENT': 'Closed (object present)'
        }
    },
    proximityCount: {
        displayName: 'Proximity (Count)',
        operator: 'count',
        default: {
            presence: 'NOT_PRESENT'
        },
        operatorDisplayNames: {
            'NOT_PRESENT': 'Open (no object present)',
            'PRESENT': 'Closed (object present)'
        }
    },
    deskOccupancy: {
        displayName: 'Desk Occupancy',
        operator: 'occupancy',
        default: {
            occupancy: 'OCCUPIED'
        },
        operatorDisplayNames: {
            'OCCUPIED': 'Occupied',
            'NOT_OCCUPIED': 'Not occupied'
        }
    },
    connectionStatus: {
        displayName: 'Offline Monitoring',
        operator: 'connectionStatus',
        default: {
            connection: "CLOUD_CONNECTOR_OFFLINE"
        },
        operatorDisplayNames: {
            'CLOUD_CONNECTOR_OFFLINE': 'Cloud Connector Offline',
            'SENSOR_OFFLINE': 'Sensor Offline'
        }
    },
    /*
    touchCount: {
        displayName: "Touch Count",
        operator: "count"
    },
    proxCount: {
        displayName: "Proximity Count",
        operator: "count"
    }
    */
};

// Returns a clear and explicit trigger description.
// E.g. 'Humidity above 15%' or 'No water present for 5 minutes'
const triggerDescription = (trigger, triggerDelay) => {
    
    const field = ruleFields[trigger.field];
    if (!field) return `Unknown trigger ${trigger.field}`;

    const unit = trigger.field === "temperature" ? getTemperatureUnitSuffix() : ruleFields[trigger.field].unit

    const humanizedDelay = durationHumanize(triggerDelay);
    const delayString = humanizedDelay ? ` for ${humanizedDelay}` : '';


    // If the upper/lower range exists, the field is temperature, and the user wants fahrenheit,
    // convert the value to fahrenheit. Otherwise leave it as-is.
    let lowerRange = trigger.range?.lower || null;
    let upperRange = trigger.range?.upper || null;
    if (lowerRange !== null && trigger.field === "temperature" && UserPreferencesManager.useFahrenheit) {
        lowerRange = celsiusToFahrenheit(lowerRange).toFixed(1);
    }
    if (upperRange !== null && trigger.field === "temperature" && UserPreferencesManager.useFahrenheit) {
        upperRange = celsiusToFahrenheit(upperRange).toFixed(1);
    }
    switch (trigger.field) {
        case 'touch':
            return `${ruleFields[trigger.field].displayName}${delayString}`;
        case 'temperature':
        case 'co2':
        case 'relativeHumidity':
            switch (trigger.range.type) {
                case 'OUTSIDE':
                case 'ABOVE':
                case 'BELOW':
                    if (lowerRange !== null && upperRange !== null) {
                        return `${field.displayName} outside range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                    }
                    if (lowerRange !== null && upperRange === null) {
                        return `${field.displayName} below ${lowerRange}${unit}${delayString}`;
                    }
                    if (lowerRange === null && upperRange !== null) {
                        return `${field.displayName} above ${upperRange}${unit}${delayString}`;
                    }
                    return 'No trigger';
                case 'WITHIN':
                    if (lowerRange !== null && upperRange !== null) {
                        return `${field.displayName} within range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                    }
                    if (lowerRange !== null && upperRange === null) {
                        return `${field.displayName} above ${lowerRange}${unit}${delayString}`;
                    }
                    if (lowerRange === null && upperRange !== null) {
                        return `${field.displayName} below ${upperRange}${unit}${delayString}`;
                    }
                    return 'No trigger';
                default: 
                    return 'No trigger';
            }
        case 'contact':
            return `${ruleFields[trigger.field].operatorDisplayNames[trigger.contact]}${delayString}`;
        case 'contactCount':
            return `Door/Window ${trigger.contact === 'OPEN' ? 'opened' : 'closed'} ${trigger.triggerCount} times`
        case 'objectPresent':
        case 'waterPresent':
            return `${ruleFields[trigger.field].operatorDisplayNames[trigger.presence]}${delayString}`;
        case 'motion':
            return `${ruleFields[trigger.field].operatorDisplayNames[trigger.motion]}${delayString}`;
        case 'touchCount':
            return `Touched ${trigger.triggerCount} times`
        case 'proximityCount':
            // return `Proximity count reaches ${trigger.triggerCount}`
            return `${trigger.presence === 'OPEN' ? 'Opened' : 'Closed'} ${trigger.triggerCount} times`
        case 'connectionStatus':
            switch (trigger.connection) {
                case 'CLOUD_CONNECTOR_OFFLINE':
                    return 'Cloud Connector offline';
                case 'SENSOR_OFFLINE':
                    return 'Sensor offline';
                default:
                    return 'No trigger';
            }
        default:
            return field.displayName;
    }
}

export const alertTriggerDescription = alert => {
    return triggerDescription(alert.trigger, alert.triggerDelay)
}

export const ruleTriggerDescription = rule => {
    return triggerDescription(rule.trigger, rule.triggerDelay)
}
