import { getTemperatureUnitSuffix } from 'services/utils';

export const plotBands = bands => ({
    xAxis: {
        plotBands: bands
    }
});

export const spacerSeriesPoint = (timestamp, value) => [timestamp, value];

export const spacerSeriesData = (xMin, xMax, yMin, yMax) => [
    spacerSeriesPoint(xMin, yMin),
    spacerSeriesPoint(xMax, yMax)
];

export const spacerSeries = (xMin, xMax, yMin, yMax, yAxis = 0) => ({
    type: 'scatter',
    className: 'graph-spacer-series-workaround',
    enableMouseTracking: false,
    showInNavigator: true,
    showInLegend: false, 
    yAxis,
    data: spacerSeriesData(xMin, xMax, yMin, yMax)
});

export const dataSeries = (name, data, extraOptions = {}) => ({
    name,
    data,
    showInNavigator: false,
    zIndex: 5,
    colorIndex: 0,
    id: 'main',
    ...extraOptions
});

export const rangeSeries = (data, yAxis = 0, seriesName = 'Min/Max') => ({
    type: 'arearange',
    name: seriesName,
    data,
    showInNavigator: false,
    colorIndex: 1,
    fillOpacity: 0.15,
    yAxis,
    zIndex: 4,
    marker: {
        enabled: false
    }
});

export const series = (...items) => ({
    series: items
});

export const temperatureSeries = (data, xMin, xMax, yMin, yMax) =>
    series(
        dataSeries('Temperature', data),
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        rangeSeries(data.map(item => [item[0], item[2], item[3]]))
    );

export const CO2OnlySeries = (data, xMin, xMax, yMin, yMax) =>
    series(
        dataSeries('CO2', data),
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        rangeSeries(data.map(item => [item[0], item[2], item[3]]))
    );

export const temperatureFilterSeries = (data, xMin, xMax, yMin, yMax) => 
    series(
        {
            name: 'Temperature',
            data: data.map(item => [item[0], item[1]]),
            showInNavigator: false,
            zIndex: 1,
            colorIndex: 1,
            className: 'faded-series',
            tooltip: {
                valueSuffix: `${getTemperatureUnitSuffix()}`,
                valueDecimals: 1
            }
        },
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        {
            name: 'Glycol Filter',
            data: data.map(item => [item[0], item[2]]),
            showInNavigator: false,
            colorIndex: 0,
            zIndex: 2,
            marker: {
                enabled: false
            },
            tooltip: {
                valueSuffix: `${getTemperatureUnitSuffix()}`,
                valueDecimals: 2
            }
        }
    )

// TILT-SUPPORT
export const tiltSeries = (data, xMin, xMax, yMin, yMax) => {
    return series(
        {
            name: "X",
            data: data.map(item => [item[0], item[1]]),
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 1,
            tooltip: {
                valueSuffix: ' G',
                valueDecimals: 2
            }
        },
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        {
            name: "Y",
            data: data.map(item => [item[0], item[2]]),
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 6,
            tooltip: {
                valueSuffix: ' G',
                valueDecimals: 2
            }
        },
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        {
            name: "Z",
            data: data.map(item => [item[0], item[3]]),
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 7,
            tooltip: {
                valueSuffix: ' G',
                valueDecimals: 2
            }
        },
        spacerSeries(xMin, xMax, yMin, yMax, 1),
    )
}

export const touchSeries = (
    data,
    xMin,
    xMax,
    yMin,
    yMax,
    extraDataSeriesOptions = {}
) =>
    series(
        dataSeries('Button presses', data, extraDataSeriesOptions),
        spacerSeries(xMin, xMax, yMin, yMax)
    );

export const proximitySeries = (data, xMin, xMax, yMin, yMax, extraDataSeriesOptions = {}) =>
    series(
        dataSeries('Proximity Status', data, extraDataSeriesOptions),
        spacerSeries(xMin, xMax, yMin, yMax)
    );

export const contactSeries = (data, xMin, xMax, yMin, yMax, extraDataSeriesOptions = {}) =>
    series(
        dataSeries('Contact Status', data, extraDataSeriesOptions),
        spacerSeries(xMin, xMax, yMin, yMax)
    );

export const connectivitySeries = (data, xMin, xMax, yMin, yMax) =>
    series(
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        ...Object.keys(data).map((cconId, index) =>
            dataSeries(cconId, data[cconId], { colorIndex: index })
        )
    );

export const cellularSeries = (data, xMin, xMax, yMin, yMax) =>
    series(
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        dataSeries('Cellular Status', data)
    );

export const extremes = (min, max) => ({
    xAxis: {
        min,
        max
    }
});

export const humiditySeries = (data, xMin, xMax, yMin, yMax) => {
    return series(
        {
            name: 'Temperature',
            data: data.map(item => [item[0], item[1]]),
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 0,
            tooltip: {
                valueSuffix: ` ${getTemperatureUnitSuffix()}`,
                valueDecimals: 2
            }
        },
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        {
            name: 'Relative humidity',
            data: data.map(item => [item[0], item[2]]),
            yAxis: 2,
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 0,
            tooltip: {
                valueSuffix: ' %',
                valueDecimals: 2
            }
        },
        rangeSeries([], 0, 'Temperature-Min/Max'),
        rangeSeries([], 2, 'Humidity-Min/Max'),
    );
}

export const analogSeries = (data, xMin, xMax, yMin, yMax) => {
    return series(
        {
            name: 'Temperature',
            data: data.map(item => [item[0], item[1]]),
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 0,
            tooltip: {
                valueSuffix: ` ${getTemperatureUnitSuffix()}`,
                valueDecimals: 2
            }
        },
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        {
            name: 'Pressure',
            data: data.map(item => [item[0], item[2]]),
            yAxis: 2,
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 0,
            tooltip: {
                valueSuffix: ' psi',
                valueDecimals: 2
            }
        },
        rangeSeries([], 0, 'Temperature-Min/Max'),
        rangeSeries([], 2, 'Pressure-Min/Max'),
    );
}

export const CO2Series = (data, xMin, xMax, yMin, yMax) => {
    return series(
        {
            name: 'CO2',
            data: data.map(item => [item[0], item[1]]),
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 0,
            tooltip: {
                valueSuffix: ` ppm`,
                valueDecimals: 0
            },
            visible: true
        },
        spacerSeries(xMin, xMax, yMin, yMax, 1),
        rangeSeries([], 0, 'CO2-Min/Max'),
        {
            name: 'Temperature',
            data: data.map(item => [item[0], item[2]]),
            yAxis: 2,
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 0,
            tooltip: {
                valueSuffix: `${getTemperatureUnitSuffix()}`,
                valueDecimals: 2
            }
        },
        rangeSeries([], 2, 'Temperature-Min/Max'),
        {
            name: 'Relative humidity',
            data: data.map(item => [item[0], item[3]]),
            yAxis: 4,
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 0,
            tooltip: {
                valueSuffix: '%',
                valueDecimals: 0
            },
        },
        rangeSeries([], 4, 'Humidity-Min/Max'),
        {
            name: 'Pressure',
            data: data.map(item => [item[0], item[4]]),
            yAxis: 6,
            showInNavigator: false,
            zIndex: 5,
            colorIndex: 0,
            tooltip: {
                valueSuffix: ` hPa`,
                valueDecimals: 0
            }
        },
        rangeSeries([], 6, 'Pressure-Min/Max')
    );
}


/**
 * Domain and range concepts here are coming from d3's scales
 * @see https://github.com/d3/d3-scale#continuous-scales
 */
const DOMAIN_START = 5 * 60 * 1000; // 5 min
const DOMAIN_END = 60 * 60 * 1000; // 60 min
const DOMAIN_SPAN = DOMAIN_END - DOMAIN_START;

const RANGE_START = 1000; // 1s
const RANGE_END = 60 * 1000; // 60s
const RANGE_SPAN = RANGE_END - RANGE_START;

export const getRefreshInterval = (min, max) => {
    const span = max - min;
    if (span > DOMAIN_END) {
        return null;
    }
    const interval = Math.round(
        RANGE_SPAN * ((span - DOMAIN_START) / DOMAIN_SPAN) + RANGE_START
    );
    return interval < RANGE_START ? RANGE_START : interval;
};

export const CLOSE_TO_COEF = 0.1; // last 10% of the axis range is considered as close to max

export const isCloseToAxisMax = (min, max, dataMax) => {
    const threshold = CLOSE_TO_COEF * (max - min);
    return dataMax - max <= threshold;
};
