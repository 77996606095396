import angular from 'angular';
import * as SensorsAndConnectors from './sensors-and-connectors';
import * as NewUserFlow from './new-user-flow';
import * as Projects from './projects';
import * as Dashboard from './dashboard';
import * as DataConnectors from './data-connectors';
import * as ProjectSettings from './project-settings';
import * as ServiceAccounts from './service-accounts';
import * as Exports from './exports';
import * as OrganizationAlerts from './organization-alerts';
import * as OrganizationDetails from './organization-details';
import * as OrganizationSettings from './organization-settings';
import * as OrganizationBilling from './organization-billing';

const mdDataTable = require('angular-material-data-table');
const gridster = require('angular-gridster2');

const dtComponents = 'dt.components';
export default dtComponents;


const module = angular
    .module(dtComponents, [mdDataTable, gridster]);

module
    .config(SensorsAndConnectors.Routes)
    .component(SensorsAndConnectors.Component.selector, SensorsAndConnectors.Component.options)
    .component(SensorsAndConnectors.ListContainer.selector, SensorsAndConnectors.ListContainer.options)
    .component(
        SensorsAndConnectors.Components.SensorChartComponent.selector,
        SensorsAndConnectors.Components.SensorChartComponent.options
    )
    .component(
        SensorsAndConnectors.Components.ConnectivityChartComponent.selector,
        SensorsAndConnectors.Components.ConnectivityChartComponent.options
    )
    .component(
        SensorsAndConnectors.Components.CloudConnectorChartComponent.selector,
        SensorsAndConnectors.Components.CloudConnectorChartComponent.options
    )
    .component(
        SensorsAndConnectors.Components.AccuracyValidationComponent.selector,
        SensorsAndConnectors.Components.AccuracyValidationComponent.options
    )
    .component(
        SensorsAndConnectors.Components.SensorConfigurationsComponent.selector,
        SensorsAndConnectors.Components.SensorConfigurationsComponent.options
    )
    .component(
        SensorsAndConnectors.Components.SensorDetailsComponent.selector,
        SensorsAndConnectors.Components.SensorDetailsComponent.options
    )
    .component(
        SensorsAndConnectors.Components.CloudConnectorDetailsComponent.selector,
        SensorsAndConnectors.Components.CloudConnectorDetailsComponent.options
    );


// THING DETAILS
const sacsComponents = SensorsAndConnectors.Components;
const details = sacsComponents.ThingDetails;
module
    .component(details.ThingName.selector, details.ThingName.options)
    .component(details.ThingDetailsLabels.selector, details.ThingDetailsLabels.options)
    .component(details.ThingDescription.selector, details.ThingDescription.options)

    .component(sacsComponents.ThingsListComponent.selector, sacsComponents.ThingsListComponent.options);

// NEW USER FLOW
module
    .config(NewUserFlow.Config)
    .component(NewUserFlow.Component.selector, NewUserFlow.Component.options)

// PROJECTS
module
    .config(Projects.Config)
    .component(Projects.Component.selector, Projects.Component.options)

// DASHBOARD
module.config(Dashboard.Config)
    .component(Dashboard.Component.selector, Dashboard.Component.options)
    .component(Dashboard.DashboardCard.selector, Dashboard.DashboardCard.options)
    .component(Dashboard.DashboardCard.SingleDevice.selector, Dashboard.DashboardCard.SingleDevice.options)
    .component(Dashboard.DashboardCard.MultipleDevices.selector, Dashboard.DashboardCard.MultipleDevices.options)
    .component(Dashboard.DashboardCard.MultipleDevices.LiveProximityCard.selector, Dashboard.DashboardCard.MultipleDevices.LiveProximityCard.options)
    .component(Dashboard.DashboardCard.MultipleDevices.LiveDeskOccupancyCard.selector, Dashboard.DashboardCard.MultipleDevices.LiveDeskOccupancyCard.options)
    .component(Dashboard.DashboardCard.MultipleDevices.LiveMotionCard.selector, Dashboard.DashboardCard.MultipleDevices.LiveMotionCard.options)
    .component(Dashboard.DashboardCard.DashboardChart.selector, Dashboard.DashboardCard.DashboardChart.options)

// DATA CONNECTORS
module.config(DataConnectors.Config)
    .component(DataConnectors.Component.selector, DataConnectors.Component.options)
    .component(DataConnectors.HTTPHeadersComponent.selector, DataConnectors.HTTPHeadersComponent.options)
    .component(DataConnectors.DataConnectorComponent.selector, DataConnectors.DataConnectorComponent.options);


// PROJECT SETTINGS
module.config(ProjectSettings.Config)
    .component(ProjectSettings.Component.selector, ProjectSettings.Component.options)
    .component(
        ProjectSettings.ProjectMemberDetailsComponent.selector,
        ProjectSettings.ProjectMemberDetailsComponent.options
    )
    .component(
        ProjectSettings.InviteMemberFormComponent.selector,
        ProjectSettings.InviteMemberFormComponent.options
    );

// SERVICE ACCOUNTS
module.config(ServiceAccounts.Config)
    .component(ServiceAccounts.Component.selector, ServiceAccounts.Component.options)
    .component(
        ServiceAccounts.ServiceAccountDetailsComponent.selector,
        ServiceAccounts.ServiceAccountDetailsComponent.options
    );

// EXPORTS
module.config(Exports.Config)
    .component(Exports.Component.selector, Exports.Component.options)
    .component(
        Exports.ExportComponent.selector,
        Exports.ExportComponent.options
    )

// ORGANIZATION ALERTS
module.config(OrganizationAlerts.Config)
    .component(OrganizationAlerts.Component.selector, OrganizationAlerts.Component.options)

// ORGANIZATION DETAILS
module.config(OrganizationDetails.Config)
    .component(OrganizationDetails.Component.selector, OrganizationDetails.Component.options)

// ORGANIZATION SETTINGS
module.config(OrganizationSettings.Config)
    .component(OrganizationSettings.Component.selector, OrganizationSettings.Component.options)
    .component(
        OrganizationSettings.OrganizationMemberDetailsComponent.selector,
        OrganizationSettings.OrganizationMemberDetailsComponent.options
    )

// ORGANIZAION BILLING
module.config(OrganizationBilling.Config)
    .component(OrganizationBilling.Component.selector, OrganizationBilling.Component.options)
