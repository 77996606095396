import angular from 'angular';

/* @ngInject */
export default function onVisibleDirectiveConfig($window, $timeout) {
    // Check if the browser supports IntersectionObserver.
    const supportsIntersectionObserver = 'IntersectionObserver' in window;

    function link($scope, $element, $attrs) {
        if (supportsIntersectionObserver) {
            // Use IntersectionObserver for efficient visibility detection.
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    // threshold 0 means even one pixel visible triggers the callback.
                    if (entry.isIntersecting) {
                        // Trigger the callback via $eval in a $timeout to ensure digest.
                        $timeout(() => {
                            $scope.$eval($attrs.onVisible);
                        });
                        // Optionally unobserve if you only need it to trigger once:
                        observer.unobserve($element[0]);
                    }
                });
            }, {
                threshold: [0]
            });

            observer.observe($element[0]);

            // Cleanup on scope destroy.
            $scope.$on('$destroy', () => {
                observer.unobserve($element[0]);
            });
        } else {
            // Fallback using scroll event listener.
            const winEl = angular.element($window);

            const safeApply = (fn) => {
                if ($scope.$$phase || $scope.$root.$$phase) {
                    fn();
                } else {
                    $scope.$apply(fn);
                }
            };

            const handler = () => {
                const rect = $element[0].getBoundingClientRect();
                const viewHeight = Math.max(document.documentElement.clientHeight, $window.innerHeight || 0);
                // Use inclusive comparisons to consider elements on the edge.
                if (rect.top <= viewHeight && rect.bottom >= 0) {
                    safeApply(() => {
                        $scope.$eval($attrs.onVisible);
                    });
                }
            };

            // Bind the scroll event.
            winEl.on('scroll', handler);

            // Use $timeout to delay the initial check so that the DOM layout is settled.
            $timeout(handler, 0);

            $scope.$on('$destroy', () => {
                winEl.off('scroll', handler);
            });
        }
    }

    return {
        restrict: 'A',
        link
    };
}
