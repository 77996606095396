import moment from "moment";
/* @ngInject */
export default class PopoverController {
    constructor(AuthService, $rootScope) {
        this.AuthService = AuthService;
        this.$rootScope = $rootScope;
    }

    // Note: This calender component was not designed to support range selection, but we will force our will upon it 🤙
    $onInit() {
        this.selectedInput = 'START'
        
        // Calendar min & max (this.minDate & this.maxDate) are provided from parent
        this.startDate = moment(this.zoomStartTime).toDate()
        this.updateStartDateText()
        this.startClock = moment(this.startDate).format('HH:mm')

        this.endDate = moment(this.zoomEndTime).toDate()
        this.updateEndDateText()
        this.endClock = moment(this.endDate).format('HH:mm')

        this.activeDate = this.startDate

        setTimeout(() => { // Add slight delay to avoid flickering during loading
            this.updateRangeVisualization()
            const calendarContainer = document.getElementsByClassName('md-calendar-scroll-mask')[0]
            calendarContainer.classList.add('loaded')
        }, 100);

        // The calender component lazy loads the cells, so when we try to add classes to the cells they might not exist yet
        // We therefore keep updating the range visualization while the popover is open
        this.rangeCSSUpdater = setInterval(() => {
            this.updateRangeVisualization()
        }, 500);
    }
    
    $onDestroy() {
        clearInterval(this.rangeCSSUpdater)
    }

    setActiveInputStart() {
        this.selectedInput = 'START'
        this.activeDate = this.startDate
    }

    setActiveInputEnd() {
        this.selectedInput = 'END'
        this.activeDate = this.endDate
    }

    onUpdate() {
        // Called each time the user selects a date in the calendar
        // Tries to provide an intuitive range selector experience

        if (this.startDate === this.endDate) {
            // Start and end date are currently the same, extend the range in whatever direction the user selected
            const startDate = moment(this.startDate)
            const activeDate = moment(this.activeDate)
            if (activeDate.isBefore(startDate)) {
                this.selectedInput = 'START'
            } else {
                this.selectedInput = 'END'
            }
        }
        
        if (this.selectedInput === 'START') {
            this.startDate = this.activeDate
            this.startClock = moment(this.startDate).startOf('day').format('HH:mm')
            this.updateStartDateText()

            const startDate = moment(this.startDate)
            const endDate = moment(this.endDate)
            if (startDate.isAfter(endDate)) {
                // Selected a startDate after endDate, set endTime to match
                this.endDate = this.startDate
                this.updateEndDateText()
            } 

            // Toggle active input
            this.selectedInput = 'END'
            this.activeDate = this.endDate

        } else if (this.selectedInput === 'END') {
            this.endDate = this.activeDate
            this.endClock = moment(this.endDate).endOf('day').format('HH:mm')
            this.updateEndDateText()
            
            const startDate = moment(this.startDate)
            const endDate = moment(this.endDate)
            if (endDate.isBefore(startDate)) {
                // Selected a endDate before start, set startDate to match
                this.startDate = this.endDate
                this.updateStartDateText()
            }
            
            // Toggle active input
            this.selectedInput = 'START'
            this.activeDate = this.startDate
        }
        this.updateRangeVisualization()
    }

    updateStartDateText() {
        this.startDateWeekday = moment(this.startDate).format('ddd')
        this.startDateMonthDay = moment(this.startDate).format('MMM D')
        this.startDateYear = moment(this.startDate).format('YYYY')
    }

    updateEndDateText() {
        this.endDateWeekday = moment(this.endDate).format('ddd')
        this.endDateMonthDay = moment(this.endDate).format('MMM D')
        this.endDateYear = moment(this.endDate).format('YYYY')
    }
    

    updateRangeVisualization() {
        // We're exploiting that each date element in the calendar has an ID in the format "YYYY-MM-D" (Note: month is 0 indexed)
        // This let's us add custom classes to visualize a range selection

        // Get an array of all dates between start and end in the needed calendar format
        const betweenDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate)

        const allDateElements = document.querySelectorAll('[id*="-month-"]') // All date elements displayed in the calendar
        allDateElements.forEach(element => {
            // Clean all elements by removing any previously added classes
            element.classList.remove('between-date-element', 'active-date-element','start', 'end')

            const dateFromId = element.id.split('-month-')[1] // This returns the "YYYY-MM-D" of the element
            if (betweenDates.includes(dateFromId)) {
                element.classList.add('between-date-element')
            }
        });

        // Add special styles to the start and end dates
        const startDate = moment(this.startDate)
        const calendarFormattedStartDate = `${startDate.format('YYYY')}-${startDate.month()}-${startDate.format('D')}` // Month needs to be zero-indexed
        const startDateElement = document.querySelector(`[id*="${calendarFormattedStartDate}"]`)
        startDateElement?.classList.add('active-date-element', 'start') // eslint-disable-line no-unused-expressions

        const endDate = moment(this.endDate)
        const calendarFormattedEndDate = `${endDate.format('YYYY')}-${endDate.month()}-${endDate.format('D')}` // Month needs to be zero-indexed
        const endDateElement = document.querySelector(`[id*="${calendarFormattedEndDate}"]`)
        endDateElement?.classList.add('active-date-element', 'end') // eslint-disable-line no-unused-expressions
    }

    // Array of dates between start and end in the format "YYYY-MM-D"
    enumerateDaysBetweenDates(startDate, endDate) { // eslint-disable-line class-methods-use-this

        const beginning = moment(startDate)
        const ending = moment(endDate)
        const now = beginning.clone()
        const dates = []
    
        while (now.isSameOrBefore(ending)) {
            dates.push(`${now.format('YYYY')}-${now.month()}-${now.format('D')}`) // Month needs to be zero-indexed
            now.add(1, 'days')
        }
        return dates
    }

    updatedStartClock() {
        this.startClock = moment(this.startDate).format('HH:mm')
    }

    updatedEndClock() {
        this.endClock = moment(this.endDate).format('HH:mm')
    }

    confirmRange() {
        // Get the time from the clock pickers
        if (this.includeClock) {
            this.startDate.setHours(this.startClock.split(':')[0], this.startClock.split(':')[1], 0)
            this.endDate.setHours(this.endClock.split(':')[0], this.endClock.split(':')[1], 59)
        } else {
            moment(this.startDate).startOf('day')
            moment(this.endDate).endOf('day')
        }
        // Callback to parent to update the range
        this.updateDateRange(this.startDate, this.endDate)
    }
}


