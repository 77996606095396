import _merge from 'lodash/merge';
import { parseQuery } from 'services/QueryParser';
import { DEFAULT_TOKEN } from 'services/PaginationHelper';
import ConfigPresets from 'services/charting/presets';
import { DataConverter } from 'services/charting'; 
import { getTemperatureUnitSuffix, celsiusToFahrenheit } from 'services/utils';

import illustrationStep1 from '../../../../../assets/images/img_accuracy_validation_illustration_1.png';
import illustrationStep2 from '../../../../../assets/images/img_accuracy_validation_illustration_2.png';
import illustrationStep3 from '../../../../../assets/images/img_accuracy_validation_illustration_3.png';
import moment from 'moment';

/* @ngInject */
export default class CalibrationInfoController {
    constructor(DialogService, SensorService, SensorEventsLoader, UserPreferencesManager, ToastService, $q, $scope, thing, loadInProgressAccuracyValidation) {
        this.DialogService = DialogService
        this.SensorService = SensorService
        this.SensorEventsLoader = SensorEventsLoader
        this.UserPreferencesManager = UserPreferencesManager
        this.ToastService = ToastService
        this.$q = $q
        this.$scope = $scope
        this.device = thing
        this.loadInProgressAccuracyValidation = loadInProgressAccuracyValidation.bind(this)
    }

    get hasInProgressValidation() {
        let validationInProgress = false
        this.referenceValidations.forEach(validation => {
            if (validation.status === "IN_PROGRESS") {
                validationInProgress = true
            }
        })
        return validationInProgress
    }

    $onInit() {
        this.accuracyValidationStep = 0

        this.illustrations = [
            illustrationStep1,
            illustrationStep2,
            illustrationStep3
        ]

        this.progress = null;
        this.isFetching =  false;
        this.orderBy = 'labels.name';
        this.query = '';
        this.previousQuery = ''; // Allows us to detect when the user has changed the query
        this.currentPageSize = 100;
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;
        this.devices = [];
        this.onReorderCallback = this.listReorder.bind(this);

        this.referenceSensor = null
        this.referenceValidations = []
        this.SensorService.listReferenceValidations(this.device.id).then((response) => {
            this.referenceValidations = response.validations
            this.referenceValidations.forEach(validation => {
                validation.formattedEndTime = moment(validation.endTime).format('MMM D, YYYY')
                validation.daysAgo = moment().diff(moment(validation.endTime), 'days')
                if (moment(validation.endTime).isSame(moment(), 'day')) {
                    validation.daysAgo = 'Today'
                } else if (moment(validation.endTime).isSame(moment().subtract(1, 'day'), 'day')) {
                    validation.daysAgo = 'Yesterday'
                } else {
                    validation.daysAgo = `${validation.daysAgo} days ago`
                }
                validation.referenceSensorId = validation.referenceSensorName.split('/').slice(-1)[0]

                // Check if temperature should be displayed in Celsius or Fahrenheit
                if(this.UserPreferencesManager.useFahrenheit) {
                    validation.formattedTemperatureOffset = `${(validation.result.targetTemperatureOffset * 9/5).toFixed(2)}${getTemperatureUnitSuffix()}`
                    validation.formattedTemperatureMean = `${(celsiusToFahrenheit(validation.result.temperatureMean)).toFixed(2)}${getTemperatureUnitSuffix()}`
                    validation.formattedTemperatureStd = `${(validation.result.temperatureStd * 9/5).toFixed(3)}${getTemperatureUnitSuffix()}`
                } else {
                    validation.formattedTemperatureOffset = `${validation.result.targetTemperatureOffset.toFixed(2)}${getTemperatureUnitSuffix()}`
                    validation.formattedTemperatureMean = `${validation.result.temperatureMean.toFixed(2)}${getTemperatureUnitSuffix()}`
                    validation.formattedTemperatureStd = `${validation.result.temperatureStd.toFixed(3)}${getTemperatureUnitSuffix()}`
                }
            })

            // Sort the validations by end time, newest first
            this.referenceValidations.sort((a, b) => {
                return moment(b.endTime).valueOf() - moment(a.endTime).valueOf()
            })
        }).catch((error) => {
            console.error(error) // eslint-disable-line no-console
        })
    }


    downloadCertificate() {
        this.SensorService.downloadCertificatePDF(this.device.id).then(response => {
            // Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            const a = document.createElement("a")
            a.style = "display: none"
            document.body.appendChild(a)
            // Create a DOMString representing the blob and point the link element towards it
            const url = window.URL.createObjectURL(response.data)
            a.href = url
            a.download = `DT Calibration Certificate ${this.device.id}.pdf`
            a.click(); // Programatically click the link to trigger the download
            // Release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url)
        }).catch(error => {
            this.ToastService.showSimpleTranslated('calibration_certificate_wasnt_downloaded')
            console.error(error); // eslint-disable-line no-console
        })
    }

    startAccuracyValidationFlow() {
        this.accuracyValidationStep = 1
        this.loadList()
    }

    loadList() {
        this.isFetching = true;
        this.$scope.$applyAsync();
        const deferred = this.$q.defer();

        // Reset orderBy when the user has changed the text in the search box.
        // This will let the backend decide the order based on a match score.
        if (this.previousQuery !== this.query) {
            if (this.query.length > 0) {
                // Let the backend decide when the user has entered a search query
                this.orderBy = '';
            } else if (this.orderBy === '') {
                // Default to labels.name if the user has removed the search query
                this.orderBy = 'labels.name';
            }
        }
        this.previousQuery = this.query;

        this.progress = this.SensorService.sensors({
            ...parseQuery(this.query),
            deviceTypes: this.deviceTypes,
            orderBy: this.orderBy,
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        }).then(({ data, nextPageToken}) => {
            this.devices = data;
            const deviceIds = this.devices.map(device => device.id)
            this.SensorService.calibrationInfo(deviceIds).then(( { calibrationInfo } ) => {
                deviceIds.forEach(deviceId => {
                    const deviceCalibrationInfo = calibrationInfo.find(device => device.deviceId === deviceId)
                    if (deviceCalibrationInfo) {
                        this.devices.find(device => device.id === deviceId).isCalibrated = deviceCalibrationInfo.isCalibrated
                        this.devices.find(device => device.id === deviceId).calibrationTime = deviceCalibrationInfo.isCalibrated ? moment(deviceCalibrationInfo.calibrationTime).format('MMM D, YYYY') : null
                    }
                });

                // Remove target device from the list
                this.devices = this.devices.filter(device => device.id !== this.device.id)
                

                // Fetch the measurement interval for each calibrated device
                const calibratedDevices = this.devices.filter(device => device.isCalibrated)
                calibratedDevices.push(this.device)
                const promises = calibratedDevices.map(device => {
                    return this.SensorService.getDeviceConfiguration(device.name).then((response) => {
                        const heartbeatInterval = response.attributes.heartbeatInterval.current.value
                        const samplesPerHeartbeat = response.attributes.samplesPerHeartbeat.current.value
                        const measurementInterval = heartbeatInterval / samplesPerHeartbeat

                        device.measurementInterval = measurementInterval
                        device.measurementIntervalFormatted = measurementInterval > 60 ? `${measurementInterval / 60} minutes` : `${measurementInterval} seconds`
                    }).catch((error) => {
                        console.error(error) // eslint-disable-line no-console
                    })
                })

                Promise.all(promises).then(() => {
                    // Sort the devices by calibration status
                    this.devices.sort((a, b) => {
                        if (a.isCalibrated && !b.isCalibrated) {
                            return -1
                        }
                        if (!a.isCalibrated && b.isCalibrated) {
                            return 1
                        }
                        return 0
                    })

                    this.isFetching = false;
                    this.$scope.$applyAsync();
                }).catch((error) => {
                    this.isFetching = false;
                    console.error(error) // eslint-disable-line no-console
                })

            }).catch((serverResponse) => {
                this.isFetching = false;
                console.error(serverResponse) // eslint-disable-line no-console
            })
            this.nextPageToken = nextPageToken;
            deferred.resolve([]);
        }).catch((serverResponse) => {
            this.isFetching = false;
            this.ToastService.showSimpleTranslated('sensor_list_wasnt_loaded', {
                serverResponse
            });
            deferred.reject();
        });

        return deferred.promise;
    }

    search( query ) {
        this.query = query;
        this.currentPageToken = DEFAULT_TOKEN;
        return this.loadList();
    }

    clearSearchResult() {
        this.query = '';
        this.currentPageToken = DEFAULT_TOKEN;
        this.loadList();
    }

    listReorder(order) {
        this.orderBy = order;
        this.currentPageToken = DEFAULT_TOKEN;
        this.loadList();
    }

    setPageSize({ pageSize }) {
        this.currentPageToken = DEFAULT_TOKEN;
        this.currentPageSize = pageSize;
        this.loadList();
    }

    setPageToken({ pageToken }) {
        this.currentPageToken = pageToken;
        this.loadList();
        this.scrollToTop();
    }

    hasValidationErrors(device) {
        if (device.id === this.device.id) {
            return true
        }
        if (device.measurementInterval !== 30) {
            return true
        }
        return !device.isCalibrated
    }

    selectReference(device) {
        if (this.hasValidationErrors(device)) {
            return
        }
        this.referenceSensor = device
        this.accuracyValidationStep = 2
    }

    calculateValidationDuration() {
        const targetMeasurementInterval = this.device.measurementInterval

        if (targetMeasurementInterval <= 60) {
            return 2
        } else if (targetMeasurementInterval <= 180) {
            return 2.5
        } else if (targetMeasurementInterval <= 300) {
            return 4
        }
        return 10
    }
        

    showValidationPreview() {

        this.loadingPreview = true
        this.accuracyValidationStep = 3

        this.validationDurationHours = this.calculateValidationDuration()

        const targetEvents = this.SensorEventsLoader.loadSince(this.device.name, ['temperature'], moment().subtract(1, 'hour') , moment()).then((events) => {
            return events
        })
        const referenceEvents = this.SensorEventsLoader.loadSince(this.referenceSensor.name, ['temperature'], moment().subtract(1, 'hour') , moment()).then((events) => {
            return events
        })

        Promise.all([targetEvents, referenceEvents]).then(([targetEventsResult, referenceEventsResult]) => {
            this.referenceSensor.events = referenceEventsResult
            this.device.events = targetEventsResult
            const chartSeries = [this.device, this.referenceSensor].map((device, index) => {
                let events = device.events
                if (events) {
                    events = DataConverter.getTemperatureSamples(events);                    
                    events.push([moment().add(this.validationDurationHours + 1, 'hour').valueOf(), null]);

                }
                return {
                    data: events,
                    name: `${index === 0 ? 'Target' : 'Reference'}: ${device.labels.name || device.name.split('/').slice(-1)[0]}`,
                    tooltip: {
                        valueDecimals: 2,
                        valueSuffix: ` ${getTemperatureUnitSuffix()}`
                    },
                };
            })
    
            this.chartConfig = _merge(
                ConfigPresets.TemperatureValidation,
                {
                    series: chartSeries
                },
                {
                    title: { text: null },
                    credits: { enabled: false },
                    scrollbar: { enabled: false },
                    navigator: { enabled: false },
                    rangeSelector: { enabled: false },
                    legend: {
                        enabled: true
                    },
                    xAxis: {
                        plotLines: [
                            {
                                color: '#FF0000',
                                width: 2,
                                value: moment().valueOf(),
                                zIndex: 5,
                                label: {
                                    useHTML: true,
                                    align: 'center',
                                    y: -14,
                                    x: -3,
                                    allowOverlap: true,
                                    text: `<div style="transform: rotate(-90deg)">Start</div>`,
                                    
                                },
                            },
                            {
                                color: '#FF0000',
                                width: 2,
                                value: moment().add(this.validationDurationHours, 'hour').valueOf(),
                                zIndex: 5,
                                label: {
                                    useHTML: true,
                                    align: 'center',
                                    y: -14,
                                    x: -3,
                                    allowOverlap: true,
                                    text: `<div style="transform: rotate(-90deg)">End</div>`,
                                    
                                },
                                
                            }
                        ],
                        plotBands: [
                            {
                                color: '#FF0000',
                                from: moment().valueOf(),
                                to: moment().add(this.validationDurationHours, 'hour').valueOf(),
                                zIndex: 5
                            }
                        ],
                    },
                    plotOptions: {
                        column: {
                            connectNulls: true,
                            getExtremesFromAll: false,
                        },
                        
                        series: {
                            animation: false,
                            shadow: false,
                            threshold: null,      
                            turboThreshold: 0,
                            boostThreshold: 0,
                            marker: {
                                enabled: false,
                            }
                        },
                    }
                }
            );
    
            this.loadingPreview = false
            this.$scope.$applyAsync()

        }).catch((error) => {
            console.error(error) // eslint-disable-line no-console
        })
    }

    startAccuracyValidationProcess() {
        const params = {
            referenceSensorName: this.referenceSensor.name,
            startTime: moment().toISOString(),
            endTime: moment().add(this.validationDurationHours, 'hour').toISOString()
        }
        this.SensorService.createReferenceValidation(this.device.id, params).then(() => {
            this.loadInProgressAccuracyValidation()
            this.closeModal()
        }).catch((error) => {
            console.error(error) // eslint-disable-line no-console
        })
    }

    closeModal() {
        this.DialogService.cancel();
    }
}
