import Base from './base';
import { Proximity, ProximityAggregated, ProximityAdvanced } from './proximity';
import { Motion, MotionAggregated } from './motion';
import AlertView from './alert-view';
import AlertViewConnectivity from './alert-view-connectivity';
import AlertViewCCONConnectivity from './alert-view-ccon-connectivity';
import Temperature from './temperature';
import TemperatureFiltered from './temperature-filtered';
import TemperatureValidation from './temperature-validation';
import Touch from './touch';
import Connectivity from './connectivity';
import CloudConnector from './ccon';
import * as plotBand from './plotBand';
import Counting from './counting';
import WaterDetector from './water-detector';
import Humidity from './humidity';
import Tilt from './tilt'; // TILT-SUPPORT
import CO2 from './co2';
import CO2Only from './co2-only';
import Analog from './analog';
import { DeskOccupancy, DeskOccupancyAggregated} from './desk-occupancy';
import { DashboardTemperature, DashboardMotion, DashboardMotionColumns, DashboardMotionHeatmap, DashboardMotionHeatmapDaily, DashboardDeskOccupancy, DashboardProximity, DashboardProximityColumns, DashboardTouch, DashboardWater, DashboardHumidity, DashboardCounting, DashboardTemperatureMultiple, DashboardHumidityMultiple, DashboardDeskOccupancyAggregated, DashboardCO2Heatmap, DashboardCO2HeatmapDaily, DashboardCO2TimeSeries } from './dashboard';

export default {
    Base,
    AlertView,
    AlertViewConnectivity,
    AlertViewCCONConnectivity,
    Proximity,
    ProximityAggregated,
    ProximityAdvanced,
    Motion,
    MotionAggregated,
    Temperature,
    TemperatureFiltered,
    TemperatureValidation,
    Touch,
    Connectivity,
    CloudConnector,
    plotBand,
    Counting,
    WaterDetector,
    Humidity,
    Tilt, // TILT-SUPPORT
    CO2,
    CO2Only,
    Analog,
    DeskOccupancy,
    DeskOccupancyAggregated,
    DashboardTemperature,
    DashboardTemperatureMultiple,
    DashboardHumidityMultiple,
    DashboardDeskOccupancyAggregated,
    DashboardMotion,
    DashboardMotionColumns,
    DashboardMotionHeatmap,
    DashboardMotionHeatmapDaily,
    DashboardDeskOccupancy,
    DashboardProximity,
    DashboardProximityColumns,
    DashboardTouch,
    DashboardWater,
    DashboardHumidity,
    DashboardCounting,
    DashboardCO2Heatmap,
    DashboardCO2HeatmapDaily,
    DashboardCO2TimeSeries
};
