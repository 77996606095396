import moment from 'moment';
import _merge from 'lodash/merge';
import _debounce from 'lodash/debounce';
import {
    APP_CONTENT_RESIZE_EVENT
} from 'services/StudioEvents';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { PLOT_BAND_OFFLINE_PREFIX, PLOT_BAND_INCOMPLETE_DATA_PREFIX } from 'services/charting/data-converter';
import { ALERT_EVENT_TYPES, RANGE_DURATIONS } from 'services/charting/constants';

const assertDependency = (service, serviceName) => {
    if (!service) {
        throw new Error(
            `Service "${serviceName}" is required to be passed to the AbstractChartController constructor`
        );
    }
};

const assertOverride = methodName => {
    throw new Error(
        `Method "${methodName}" should be overridden in the child class`
    );
};

const PAN_TRACKING_DEBOUNCE_DELAY = 250; // in milliseconds

const CHART_X_AXIS_INDEX = 0;
const CHART_DATA_SERIES_INDEX = 0;
const CHART_SPACER_SERIES_INDEX = 1;

const MESSAGE_LOADING = 'Loading data from server...';

/* eslint class-methods-use-this: 0 */

/**
 * @class AbstractChartController
 *
 * @external {moment} https://momentjs.com/
 *
 * @property {SensorEventsLoader} SensorEventsLoader
 * @property {ToastService} ToastService
 * @property {Object} thing
 * @property {boolean} initialized
 * @property {Function} onInitialized Callback to notify parent about chart initialization
 * @property {number} spacerStart The beginning of the scrollable time span
 * @property {number} spacerEnd The end of the scrollable time span (most often current time)
 *
 * These properties are used in charts with variable Y values e.g. temperature
 * @property {number} spacerMin The minimum data value of the Y Axis
 * @property {number} spacerMax The maximum data value of the Y Axis
 *
 * @property {number} initialXAxisMin The beginning of the initial scrollable window in unix timestamp format
 * @property {number} initialXAxisMax The end of the initial scrollable window in unix timestamp format
 *
 * @property {number} chartMin The beginning of the loaded timespan in unix timespan format
 * @property {number} chartMax The end of the loaded timestamp in unix timespan format (current time)
 *
 * @property {?moment} dataLoadedSince The beginning of the currently loaded time span
 * @property {boolean} loadingSince The flag telling if the data is currently being loaded
 * @property {boolean} needsReloadSince The flag telling if the data needs to be reloaded from the new since
 */
class AbstractChartController {
    constructor({
        SensorEventsLoader,
        ToastService,
        $rootScope,
        EventEmitter,
    }) {
        assertDependency(SensorEventsLoader, 'SensorEventsLoader');
        assertDependency(ToastService, 'ToastService');
        assertDependency($rootScope, '$rootScope');
        assertDependency(EventEmitter, 'EventEmitter');

        this.SensorEventsLoader = SensorEventsLoader;
        this.ToastService = ToastService;
        this.$rootScope = $rootScope;
        this.EventEmitter = EventEmitter;

        this.initializeChart = this.initializeChart.bind(this);
        this.processLazyEvents = this.processLazyEvents.bind(this);
        this.handleError = this.handleError.bind(this);
        this.onAfterSetExtremes = this.onAfterSetExtremes.bind(this);
        this.handleChartSelection = this.handleChartSelection.bind(this);
        this.updateSpacerSeries = this.updateSpacerSeries.bind(this);

        // Prevents loadMissingData from being called more frequently than PAN_TRACKING_DEBOUNCE_DELAY 
        // milliseconds. This function will be called any time the time range changed, either because 
        // the chart was panned or zoomed, or because the time picker was used to change the time range.
        this.loadMissingData = _debounce(
            this.loadMissingData.bind(this),
            PAN_TRACKING_DEBOUNCE_DELAY
        );
    }

    $onInit() {        
        this.initialized = false;
        
        // Is set to true while loading new events
        this.isLoadingData = false;

        // Is set to true if there was a reason to load more events while events were
        // being loaded (loadingSince = true). In this case, the events will be loaded
        // after the current load is finished.
        this.needsReloadSince = false;

        // Sets the user scrollable time range
        this.spacerStart = moment().subtract(RANGE_DURATIONS[this.greatestRangeLabel], 'milliseconds').valueOf();
        this.spacerEnd = moment().valueOf();

        // Raw events
        this.loadedEvents = [];
        // Highchart formatted events or aggregated data
        this.formattedData = [];
        // Highchart formatted samples (will be empty if range is more than 8 days)
        this.formattedSamples = [];

        // Set to true while the extremes are being updated by the spacer ticker (or new events).
        // This is to prevent requesting new data from the API every time the spacer ticker updates.
        // We'll get new events from the stream, so the API call is unnecessary.
        this.extremesUpdatedThroughTicker = false;

        // Y-axis
        this.spacerMin = 0;
        this.spacerMax = 0;

        this.setLoadedEvents([]);

        if (this.alertEvents) { // Set up trigger and resolve events and zoom to the alert duration

            this.triggerEvent = this.alertEvents.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED) || null;
            this.resolveEvent = this.alertEvents.find(event => event.type === ALERT_EVENT_TYPES.RESOLVED) || null;
            
            // Calculate the padding around the alert to ensure that the alert is centered in the chart, 20% of the alert duration or 6 hours, whichever is greater.
            const alertDuration = moment(this.resolveEvent ? this.resolveEvent.createTime : moment()).diff(moment(this.triggerEvent.triggered.deviceTriggerTime));
            const padding = Math.max(alertDuration * 0.2, moment.duration(6, 'hours').asMilliseconds());

            const paddedAlertStart = moment(this.triggerEvent.triggered.deviceTriggerTime).subtract(padding, 'milliseconds');
            const paddedAlertEnd = this.resolveEvent ? moment.min(moment(this.resolveEvent.createTime).add(padding, 'milliseconds'), moment()) : moment();

            // Sets the time range to fetch data for
            this.chartMin = paddedAlertStart.valueOf();
            this.chartMax = paddedAlertEnd.valueOf();

            // Defines the initial extremes for the chart
            this.initialXAxisMin = paddedAlertStart.valueOf();
            this.initialXAxisMax = paddedAlertEnd.valueOf();
            
            this.setCurrentExtremes([this.initialXAxisMin, this.initialXAxisMax]);

            // Keeps track of the earliest timestamp that we have finished loading events for.
            this.dataLoadedSince = this.chartMin;

            this.initializeSince(paddedAlertStart, paddedAlertEnd);

        } else {  // Normal case
            
            this.triggerEvent = null;
            this.resolveEvent = null;

            // Sets the time range to fetch data for
            const chartMin = moment()
                .subtract(1, 'day')
                .startOf('day');
            const chartMax = moment();
            this.chartMin = chartMin.valueOf();
            this.chartMax = chartMax.valueOf();

            // Defines the initial extremes for the chart
            this.initialXAxisMax = moment().valueOf();
            this.initialXAxisMin = moment()
                .subtract(1, 'day')
                .valueOf();
            
            this.setCurrentExtremes([this.initialXAxisMin, this.initialXAxisMax]);

            // Keeps track of the earliest timestamp that we have finished loading events for.
            this.dataLoadedSince = chartMin;

            this.initializeSince(chartMin, chartMax);
        }


        this.removeResizeListener = this.$rootScope.$on(
            APP_CONTENT_RESIZE_EVENT, () => {

                // This is a workaround to force the chart to redraw plotbands when the window is resized.
                const allPlotBandsIds = [] 
                this.xAxis.plotLinesAndBands.forEach(band => {
                    if (band.id.startsWith(PLOT_BAND_OFFLINE_PREFIX) || band.id.startsWith(PLOT_BAND_INCOMPLETE_DATA_PREFIX) || band.id.startsWith('alert')) {
                        allPlotBandsIds.push(band.id)
                    }
                })
                // Clear them all before redraw
                allPlotBandsIds.forEach(bandId => { 
                    this.xAxis.removePlotBand(bandId)
                })

                if (this.triggerEvent) {
                    this.addTriggerPlotLine()
                    if (this.triggerEvent.triggered.triggerDelay !== null) {
                        this.addTriggerDelayPlotBand()
                    }
                }

                if (this.resolveEvent) {
                    this.addResolvePlotLine()
                }

                setTimeout(() => {
                    this.plotBands?.forEach(band => { // eslint-disable-line no-unused-expressions
                        this.xAxis.addPlotBand(band);
                    })
                }, 1)
            }
        )
    }

    $onDestroy() {
        this.removeResizeListener()
        this.stopSpacerTicker();
    }

    get xAxis() {
        return this.chart.xAxis[CHART_X_AXIS_INDEX];
    }

    get dataSeries() {
        return this.chart.series[CHART_DATA_SERIES_INDEX];
    }

    get spacerSeries() {
        return this.chart.series[CHART_SPACER_SERIES_INDEX];
    }

    /**
     * This method must be overridden!
     *
     * It should return the array of the event names that should be fetched from the API.
     * These events are used to draw the chart properly.
     */
    getEventTypes() {
        assertOverride('getEventTypes()');
    }

    /**
     * This method must be overridden when dataToLoad() returns "aggregated!
     * 
     * It should return the array of the aggregation fields that should be fetched from the API.
     * @returns {Array} An array of aggregation fields
     */
    getAggregationFields() {
        assertOverride('getAggregationFields()');
    }

    /**
     * This method must be overridden!
     *
     * It should accept the array of raw events fetched from the API,
     * and should return the object { data, plotBands } where:
     * data is an array of Highcharts point format,
     * plotBands is an array of Highcharts plot bands format.
     */
    convertEvents() {
        assertOverride('convertEvents(events)');
    }

    /**
     * This method must be overridden!
     * 
     * It should accept the aggregated data fetched from the API,
     * and should return the object { data, plotBands } where:
     * data is an array of Highcharts point format,
     * plotBands is an array of Highcharts plot bands format.
     */
    convertAggregated() {
        assertOverride("convertAggregated(aggregatedData)");
    }

    /**
     * This method could be overridden in some cases.
     *
     * @returns array
     */
    convertSamples() {
        return []
    }

    /**
     * This method must be overridden!
     *
     * It should accept the data (from the convertEvents method)
     * and return the Highcharts series config.
     */
    convertToSeries() {
        assertOverride(
            'convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax)'
        );
    }

    /**
     * This method must be overridden!
     *
     * It should return the Highcharts configuration preset that defines the chart-specific options.
     */
    getConfigPreset() {
        assertOverride('getConfigPreset()');
    }

    /**
     * Used by AbstractChartControlled to determine if it should load raw events, aggregated data,
     * or no data at all. This function _must_ return either "events", "aggregated", or "".
     * 
     * @param {number} days The number of days to load data for
     * @returns {string} Either "events", "aggregated", or "" for no data
     */
    dataToLoad(days) {
        // The default is to return raw events for up to 31 days, and aggregated data for longer time ranges.
        return days > 31 ? "aggregated" : "events";
    }

    /**
     * This method must be overridden!
     *
     * It should add state event to chart.
     */
    onStateEventReceived() {
        assertOverride('onStateEventReceived(eventData)');
    }

    /**
     * This method could be overridden in some cases.
     *
     * @returns boolean
     */
    shouldUseStockChart() {
        return false;
    }

    /**
     * This method could be overridden in some cases.
     *
     * @returns object
     */
    onChartPan(event) {
        return event 
    }

    /**
     * This method could be overridden in some cases.
     *
     * It should update the current offline plot band to stretch to the current moment.
     */
    syncOfflinePlotBand() {
        const lastSeen = this.thing.lastSeen;
        const from = moment(lastSeen || this.chartMin).valueOf();
        const id = DataConverter.plotBandId(from);

        this.xAxis.removePlotBand(id);
        this.xAxis.addPlotBand(DataConverter.plotBand(from, this.chartMax));
    }

    /**
     * This method could be overridden in some cases.
     *
     * It should apply an update for a chart if it is required e.g. extend the line to current moment.
     */
    addNetworkEventPoint() {}

    /**
     * This method could be overridden in some cases.
     */
    syncAdditionalDataSeries() {}

    /**
     * Returns the desired range to load data for, based on the current range visible in the chart.
     * This can be overridden in subclasses to either extend the range to ensure all the necessary
     * data is loaded, or limited to prevent unnecessary data from being loaded. 
     * 
     * Must return on object of the following format: { start: Moment, end: Moment }
     * 
     * The default behavior is to extend the chart range by either the bucket size (if we're aggregating),
     * or by 1 hour (if we're not aggregating).
     * 
     * @param {Moment} chartStart The start time of the chart
     * @param {Moment} chartEnd The end time of the chart
     */
    dataLoadRange(chartStart, chartEnd) {
        let extension = 3600

        const bucketSize = this.aggregationBucketSizeSeconds(chartStart, chartEnd)
        if (bucketSize) {
            extension = bucketSize
        }

        return {
            start: chartStart.subtract(extension, 'seconds'),
            end: chartEnd.add(extension, 'seconds')
        }
    }

    /**
     * The number of seconds to use as the bucket size for aggregation.
     * 
     * Can optionally be overridden in subclasses to provide a custom bucket size.
     * 
     * @param {Moment} start The start time to load data from
     * @param {Moment} end The end time to load data to
     * @returns 
     */
    aggregationBucketSizeSeconds(start, end) {
        return this.SensorEventsLoader.aggregationBucketSizeSeconds(start, end);
    }

    /**
     * Loads either events or an aggregated result from the API.
     * 
     * @param {Moment} startTime The start time to load data from
     * @param {Moment} endTime The end time to load data to
     * @returns {Promise} A promise that resolves to an array of events or an aggregated result
     */
    async loadEvents(startTime, endTime) {
        // Calculate the bucket size based on the chart range and not the provided startTime and endTime
        // since those might be extended to load more data than the chart.
        const chartMin = moment(this.currentExtremes[0]);
        const chartMax = moment(this.currentExtremes[1]);

        const days = endTime.diff(startTime, 'days');
        const dataToLoad = this.dataToLoad(days);
        
        // Load aggregated data if the range is more than 30 days
        if (dataToLoad === 'aggregated') {
            const bucketSize = this.aggregationBucketSizeSeconds(chartMin, chartMax);
            
            const aggregatedData = await this.SensorEventsLoader.loadAggregatedData(
                this.thing.name,
                this.getAggregationFields(),
                startTime,
                endTime,
                `${bucketSize}s`,
            ).catch(this.handleError);
            
            const { plotBands, data } = this.convertAggregated(aggregatedData);
            
            this.formattedData = data;
            this.formattedSamples = [];
            this.setLoadedEvents([]);

            this.setMetadata({
                bucketSizeSeconds: bucketSize,
            });
            
            return plotBands;
        } else if (dataToLoad === 'events') {
            const events = await this.SensorEventsLoader.loadSince(
                this.thing.name,
                this.getEventTypes(),
                startTime,
                endTime
            ).catch(this.handleError);
            
            
            // Get Highchart formatted data
            const { plotBands, data } = this.convertEvents(events);
            this.formattedData = data
            if (days < 8) {
                this.formattedSamples = this.convertSamples(events);
            } else {
                this.formattedSamples = [];
            }

            this.setLoadedEvents(events);

            this.setMetadata({
                bucketSizeSeconds: null, // Implies raw events
            });
            
            return plotBands;
        } 

        // The subclass has indicated that no data should be loaded
        this.formattedData = [];
        this.formattedSamples = [];
        this.setLoadedEvents([]);

        return [];
    }

    /**
     * Called by $onInit, and is the first trigger point to load data for the chart.
     * 
     * @param {Moment} startTime The start time to load initial data from
     * @param {Moment} endTime The end time to load data to
     */
    initializeSince(startTime, endTime) {
        this.loadEvents(startTime, endTime).then(this.initializeChart);
    }

    /**
     * Called after the first batch of events is loaded. Initializes the chart config,
     * including adding the events to the chart.
     * 
     * @param {Array} events The initial batch of events
     */
    initializeChart(plotBands) {
        if (this.formattedSamples.length > 0) {
            this.initializeChartConfig(this.formattedSamples, plotBands);
        } else {
            this.initializeChartConfig(this.formattedData, plotBands);
        }
        
        this.onInitialized();
    }

    /**
     * Called when events are loaded after the initial load. The array of events to set as the loaded set of events
     * @param {Array} events 
     */
    processLazyEvents(plotBands) {
        const allPlotBandsIds = [] 
        this.xAxis.plotLinesAndBands.forEach(band => {
            if (band.id.startsWith(PLOT_BAND_OFFLINE_PREFIX) || band.id.startsWith(PLOT_BAND_INCOMPLETE_DATA_PREFIX)) {
                allPlotBandsIds.push(band.id)
            }
        })
        // Clear them all before redraw
        allPlotBandsIds.forEach(bandId => { 
            this.xAxis.removePlotBand(bandId)
        })

        plotBands.forEach(band => {
            this.xAxis.addPlotBand(band);
        });

        // Show [samples] values if range is less than 8 days
        if (this.formattedSamples.length > 0) {
            this.dataSeries.setData(this.formattedSamples);    
        } else {
            this.dataSeries.setData(this.formattedData);
        }
        this.syncAdditionalDataSeries()
    }

    /**
     * Prepares the initial chart config, including the preset from subclasses, and the
     * initial batch of events and plot bands (offline, boost, etc).
     * 
     * @param {Array} data The initial batch of events
     * @param {Array} plotBands The initial plot bands (offline, boost, etc)
     */
    initializeChartConfig(data, plotBands) {
        this.chartConfig = _merge(
            {
                chart: {
                    events: {
                        selection: this.handleChartSelection
                    }
                }
            },
            ConfigPresets.Base,
            this.getConfigPreset(),
      
            this.convertToSeries(
                data,
                this.spacerStart,
                this.spacerEnd,
                this.spacerMin,
                this.spacerMax
            ),
            ConfigHelper.plotBands(plotBands),
            {
                xAxis: {
                    min: this.initialXAxisMin,
                    max: this.initialXAxisMax,
                    events: {
                        afterSetExtremes: this.onAfterSetExtremes
                    }
                }
            }
        );
        if (this.alertView) {
            this.chartConfig = _merge(this.chartConfig, ConfigPresets.AlertView);
            if (this.showBigFormat) {
                this.chartConfig.chart.height = 276;
            }
        }
        
        this.initialized = true;
    }

    onChartLoaded({ chart }) {
        this.chart = chart;
        if (this.triggerEvent) { // Special case for alerts
            // Calculate the padding around the alert to ensure that the alert is centered in the chart, 20% of the alert duration or 6 hours, whichever is greater.
            const alertDuration = moment(this.resolveEvent ? this.resolveEvent.createTime : moment()).diff(moment(this.triggerEvent.triggered.deviceTriggerTime));
            const padding = Math.max(alertDuration * 0.2, moment.duration(6, 'hours').asMilliseconds());
            
            const paddedAlertStart = moment(this.triggerEvent.triggered.deviceTriggerTime).subtract(padding, 'milliseconds').valueOf();
            const paddedAlertEnd = this.resolveEvent ? moment.min(moment(this.resolveEvent.createTime).add(padding, 'milliseconds'), moment()).valueOf() : moment().valueOf();
            
            this.xAxis.setExtremes(paddedAlertStart, paddedAlertEnd);
        } else { // Default case
            this.setRange(this.range);
        }
    }

    handleChartSelection() {
        this.onChartSelection();
    }

    /**
     * Called automatically be the chart every time the extremes changes.
     */
    onAfterSetExtremes({ trigger, min, max }) {
        let shouldUpdateDataSeries = true
        if (trigger === 'zoom') { 
            // The user has zoomed by dragging-to-select on the chart.
        } else if (trigger === 'navigator') {
            // The user has used the scrollbar or the arrows at the bottom of the chart
            // to change the time range.
            this.onChartPan(
                this.EventEmitter({
                    extremes: [min, max]
                })
            )
            // Avoid calling setData on Highcharts when panning the data
            shouldUpdateDataSeries = false 
        }

        // Don't load new data if the extremes were updated by the spacer ticker.
        if (this.extremesUpdatedThroughTicker === false) {
            this.loadMissingData();
        }

        this.setCurrentExtremes([min, max]);

        if (shouldUpdateDataSeries && this.loadedEvents.length > 0) {
            // TODO: This work is only necessary when new data is arriving from the stream (backfill, etc).
            // Instead of doing this work here, we should do it in the stream handler. The loadMissingData
            // will already do this indirectly (through loadData), so doing it here means the default behavior
            // is to process the data twice every time we load it.
            this.formattedData = this.convertEvents(this.loadedEvents).data
            this.formattedSamples = this.convertSamples(this.loadedEvents)
            const daysRange = moment(max).diff(moment(min), 'days')
            if (daysRange < 8 && this.formattedSamples.length > 0) {
                // Highchart can alter provided values, use a copy to avoid changing the formattedSamples
                const samplesCopy = [...this.formattedSamples] 
                this.dataSeries.setData(samplesCopy);
            } else {
                this.dataSeries.setData(this.formattedData);
            }
            this.syncAdditionalDataSeries()
        } 
    }

    /**
     * Figures out if the new start of the chart is earlier than the earliest data we have,
     * and triggers a load of the missing data if it is by calling loadDataSince.
     * Called when we might have to load more data (eg. because the time range has changed).
     */
    loadMissingData() {
        // If we're already loading data, flag that we need to reload once the current 
        // load is finished.
        if (this.isLoadingData) {
            this.needsReloadSince = true;
            return;
        }

        const { min, max } = this.xAxis.getExtremes();

        // Defines the start and end time to fetch data for. Extending the extremes
        // of the chart by an hour on either side to ensure 
        const {start, end } = this.dataLoadRange(moment(min), moment(max))

        this.chartMin = start.valueOf();
        this.chartMax = end.valueOf();

        this.chart.showLoading(MESSAGE_LOADING);
        this.isLoadingData = true;
        this.stopSpacerTicker();

        // TODO:
        // Ideally, should keep data already loaded, and only load the data needed to extend the existing segment
        // at the current resolution. If the resolution or the time range changes significantly (user scrolls fast
        // using the scrollbar), we can discard the current segment and start over.

        this.loadEvents(start, end)
            .then(this.processLazyEvents)
            .then(() => {
                this.dataLoadedSince = start;
                this.isLoadingData = false;
                this.chart.hideLoading();
                
                // Load more data if loadMissingData was called again while we were loading data.
                if (this.needsReloadSince) {
                    this.needsReloadSince = false;
                    this.loadMissingData();
                    return;
                }

                // Start the ticker to progress the timeline if the user is close enough to the end of the chart.
                if (ConfigHelper.isCloseToAxisMax(min, max, this.spacerEnd)) {
                    const interval = ConfigHelper.getRefreshInterval(min, max);
                    if (interval !== null) {
                        this.initSpacerTicker(interval);
                    }
                }

                if (this.thresholds) {
                    this.addYAxisPlotLines()
                }

                if (this.triggerEvent) {
                    this.addTriggerPlotLine()
        
                    if (this.triggerEvent.triggered.triggerDelay !== null) {
                        this.addTriggerDelayPlotBand()
                    }
                }

                if (this.resolveEvent) {
                    this.addResolvePlotLine()
                }
            });
    }

    handleError(serverResponse) {
        this.ToastService.showSimpleTranslated('device_events_wasnt_loaded', {
            serverResponse
        });
        return [];
    }

    /**
     * Extends either offline plot bands, or state segments to the end of the timeline.
     */
    addHeartbeatEventPoint() {
        if (this.thing.offline) {
            this.syncOfflinePlotBand();
        } else {
            this.addNetworkEventPoint();
        }
    }

    /**
     * Moves the end of the chart to "now" (both chartMax and spacerEnd).
     */
    updateSpacerSeries() {
        this.extremesUpdatedThroughTicker = true;

        this.chartMax = moment().valueOf();
        this.spacerEnd = this.chartMax;
        
        // This will trigger a synchronous call to onAfterSetExtremes, meaning setData doesn't
        // return until after onAfterSetExtremes has returned.
        this.spacerSeries.setData(
            ConfigHelper.spacerSeriesData(
                this.spacerStart,
                this.spacerEnd,
                this.spacerMin,
                this.spacerMax
            )
        );

        this.extremesUpdatedThroughTicker = false;

        this.addHeartbeatEventPoint();
    }

    initSpacerTicker(delay) {
        this.spacerTicker = setInterval(this.updateSpacerSeries, delay);
    }

    stopSpacerTicker() {
        clearTimeout(this.spacerTicker);
    }

    get isCloseToAxisMax() {
        const { min, max } = this.xAxis.getExtremes();
        return ConfigHelper.isCloseToAxisMax(min, max, this.chartMax);
    }

    setRange(label) {

        // Ensure that all offline plot bands are removed before setting the new range.
        const allPlotBandsIds = [] 
        this.xAxis.plotLinesAndBands.forEach(band => {
            if (band.id.startsWith('offline-since') || band.id.startsWith('alert')) {
                allPlotBandsIds.push(band.id)
            }
        })
        // Clear them all before redraw
        allPlotBandsIds.forEach(bandId => { 
            this.xAxis.removePlotBand(bandId)
        })

        const chartMin = this.spacerStart;
        const chartMax = this.spacerEnd;

        const { max } = this.xAxis.getExtremes();

        if (label === this.greatestRangeLabel) {
            // The user has selected the maximum time frame available to them. Extend the extremes
            // to the maximum time frame available.
            this.xAxis.setExtremes(chartMin, chartMax);
        } else {
            // Try to keep the current end (max), and extend the start (min) to the new range.
            // If the new start becomes earlier than the earliest minimum (chartMin), and start
            // at chartMin, and set the new end to be the duration of `label` past chartMin.
            const newMin = max - RANGE_DURATIONS[label];
            if (newMin < chartMin) {
                this.xAxis.setExtremes(
                    chartMin,
                    chartMin + RANGE_DURATIONS[label]
                );
            } else {
                this.xAxis.setExtremes(newMin, max);
            }
        }

        this.updateYAxisExtreme()
    }

    updateYAxisExtreme() {
        if (this.currentUpperYExtreme) {
            const newLow = Math.min(this.currentLowerYExtreme, this.chart.yAxis[0].dataMin)
            const newMax = Math.max(this.currentUpperYExtreme, this.chart.yAxis[0].dataMax)
            
            this.chart.yAxis[0].setExtremes(newLow, newMax)
        }
    }

    /**
     * Writes a batch of events to loadedEvents
     * 
     * @param {Array} events The list of events to set as the loaded events
     */
    setLoadedEvents(events) {
        this.loadedEvents = events;
        this.$rootScope.$applyAsync();
    }

    setCurrentExtremes(extremes) {
        this.currentExtremes = extremes;
        this.$rootScope.$applyAsync();
    }

    setMetadata(metadata) {
        this.metadata = metadata;
        this.onMetadataChanged(this.EventEmitter(metadata));
    }

    $onChanges(changes) {
        if (
            changes.eventsObservable &&
            changes.eventsObservable.currentValue &&
            changes.eventsObservable.currentValue !==
                changes.eventsObservable.previousValue
        ) {
            const events$ = changes.eventsObservable.currentValue;
            events$.subscribe(event => {
                if (
                    this.chart &&
                    this.getEventTypes().includes(event.eventType) &&
                    !this.metadata?.bucketSizeSeconds // Don't add events to the chart if we're aggregating
                ) {

                    // TODO: Instead of updating loadedEvents here, it should insert the event into the correct
                    // place in this.formattedData. This will be more efficient, since we won't have to re-process
                    // all the data twice. Need to make sure that offline plot bands are handled correctly in 
                    // the case we receive backfill events.
                   
                    // Find correct index to insert event
                    let insertIndex = this.loadedEvents.length - 1
                    const newEventTimestamp = new Date(event.timestamp).getTime()
                    for (let index = this.loadedEvents.length - 1; index >= 0; index--) {
                        const eventTimestamp = new Date(this.loadedEvents[index].timestamp).getTime()
                        if (eventTimestamp < newEventTimestamp) {
                            break
                        }
                        insertIndex = index
                    }
                    this.loadedEvents.splice(insertIndex, 0, event)

                    this.setLoadedEvents(this.loadedEvents);
                    if (event.eventType === this.eventType) {
                        this.onStateEventReceived(event.data[this.eventType]);
                        const { min, max, dataMax } = this.xAxis.getExtremes();
                        if (ConfigHelper.isCloseToAxisMax(min, max, dataMax)) {
                            this.updateSpacerSeries();
                        }
                    }
                }
            });
        }
        if (
            changes.range &&
            changes.range.currentValue !== changes.range.previousValue &&
            changes.range.currentValue
        ) {
            if (changes.range.currentValue === 'RESET_RANGE_LAST_7_DAYS') {
                // This is a special case for alert graphs where we want to reset the range to the last 7 days.
                this.xAxis.setExtremes(moment().subtract(7, 'day').valueOf(), moment().valueOf());
            } else if (this.chart){
                this.setRange(changes.range.currentValue);
            }
        }

        if (this.chart && this.thresholds) {
            if (changes.thresholds?.currentValue !== changes.thresholds?.previousValue) {
                this.addYAxisPlotLines()
            }
        }
    }

    convertSecondsDuration(duration) {
        const hours = duration.asHours();
        const minutes = duration.asMinutes();

        // Not using moment.js .humanize() because is rounds too much
        // Return example 1h 30m or 30m
        if (hours >= 1) {
            return `${Math.floor(hours)} hour${Math.floor(hours) === 1 ? '' : 's'} ${Math.floor(minutes % 60)} minute${Math.floor(minutes % 60) === 1 ? '' : 's'}`;
        }
        return `${Math.floor(minutes)} minute${Math.floor(minutes % 60) === 1 ? '' : 's'}`;
    }

    addTriggerDelayPlotBand() {

        const triggerTime = moment(this.triggerEvent.triggered.deviceTriggerTime).valueOf()

        this.xAxis.removePlotLine('alert-trigger')

        this.xAxis.addPlotLine({
            value: triggerTime,
            id: 'alert-trigger',
            className: 'trigger-delay-start',
            zIndex: 8,
            label: {
                useHTML: true,
                align: 'center',
                className: 'trigger-actual-label',
                y: -4,
                x: 0,
                allowOverlap: true,
                text: `
                    <div class="delay-label">
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50078 11.6998C7.87991 11.6998 9.20255 11.1519 10.1777 10.1768C11.1529 9.20157 11.7008 7.87893 11.7008 6.4998C11.7008 5.12068 11.1529 3.79804 10.1777 2.82285C9.20255 1.84766 7.87991 1.2998 6.50078 1.2998C5.12165 1.2998 3.79901 1.84766 2.82383 2.82285C1.84864 3.79804 1.30078 5.12068 1.30078 6.4998C1.30078 7.87893 1.84864 9.20157 2.82383 10.1768C3.79901 11.1519 5.12165 11.6998 6.50078 11.6998ZM6.98828 3.2498C6.98828 3.12051 6.93692 2.99651 6.84549 2.90509C6.75407 2.81367 6.63007 2.7623 6.50078 2.7623C6.37149 2.7623 6.24749 2.81367 6.15607 2.90509C6.06464 2.99651 6.01328 3.12051 6.01328 3.2498V6.4998C6.01328 6.7689 6.23168 6.9873 6.50078 6.9873H9.10078C9.23007 6.9873 9.35407 6.93594 9.44549 6.84452C9.53692 6.75309 9.58828 6.6291 9.58828 6.4998C9.58828 6.37051 9.53692 6.24651 9.44549 6.15509C9.35407 6.06367 9.23007 6.0123 9.10078 6.0123H6.98828V3.2498Z" fill="#906B62" style="fill:#906B62;fill:color(display-p3 0.5634 0.4191 0.3831);fill-opacity:1;"/>
                        </svg>
                    </div>
                `,
                style: {
                    transform: 'none',
                }
            },
        });

        // Convert trigger.triggerDelay on format "1800s" (number of seconds) to milliseconds
        const delaySeconds = parseInt(this.triggerEvent.triggered.triggerDelay, 10)
        const delay = this.convertSecondsDuration(moment.duration(delaySeconds, 'seconds'))

        this.xAxis.removePlotBand('alert-trigger-delay')

        // Then add the new plot band with slightly modified options
        this.xAxis.addPlotBand({
            from: triggerTime,
            to: triggerTime + delaySeconds * 1000,
            id: 'alert-trigger-delay',
            triggerDelay: delay,
            color: 'rgba(68, 170, 213, 0.5)',
            className: 'trigger-delay',
            zIndex: 1,  // Higher z-index to ensure visibility
            events: {
                mouseover(tooltipTarget) {
                    this.axis.chart.tooltip.options.enabled = false
                    const chart = this.axis.chart;
                    chart.triggerDelay = chart.renderer.text('', tooltipTarget.offsetX - 80, -20, true).add();
                    chart.triggerDelay
                        .show()
                        .addClass('highcharts-trigger-delay-label')
                        .attr({
                            text: `<div style="width: 80px; display: inline-flex;">Delay Period:</div> ${this.options.triggerDelay}`,
                        });
                },
                mouseout() {
                    this.axis.chart.tooltip.options.enabled = true
                    const chart = this.axis.chart;
                    if (chart.triggerDelay) {
                        chart.triggerDelay
                            .removeClass('highcharts-trigger-delay-label')
                            .hide();
                    }
                },
            },
        });
    }

    addTriggerPlotLine() {

        // Remove any existing trigger delay plot band
        this.xAxis.removePlotBand('alert-period-overlay')

        // Add plot band from trigger time to either resolve time or end of chart
        const triggerTime = moment(this.triggerEvent.createTime).valueOf()
        const endTime = this.resolveEvent ? moment(this.resolveEvent.createTime).valueOf() : this.xAxis.max
        
        this.xAxis.addPlotBand({
            from: triggerTime,
            to: endTime,
            id: 'alert-period-overlay',
            className: 'period-overlay',
            zIndex: 1
        });

        this.xAxis.removePlotLine('alert-trigger-actual')

        this.xAxis.addPlotLine({
            value: triggerTime, 
            id: 'alert-trigger-actual',
            className: 'trigger-actual',
            zIndex: 2,
            label: {
                useHTML: true,
                align: 'center',
                className: 'trigger-actual-label',
                y: -4,
                x: 0,
                allowOverlap: true,
                text: `
                    <div style="">
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.82193 2.31733L11.3439 9.36057C11.4566 9.586 11.5099 9.83643 11.4985 10.0882C11.4872 10.34 11.4117 10.5847 11.2791 10.7991C11.1466 11.0134 10.9615 11.1904 10.7414 11.3131C10.5212 11.4357 10.2734 11.5001 10.0213 11.5H2.97806C2.72609 11.5 2.4783 11.4356 2.25823 11.3129C2.03816 11.1901 1.85312 11.0131 1.72067 10.7988C1.58823 10.5844 1.51278 10.3398 1.50149 10.0881C1.49019 9.83636 1.54344 9.58593 1.65616 9.36057L5.1775 2.31733C5.30021 2.07174 5.48893 1.86519 5.7225 1.72083C5.956 1.57646 6.22514 1.5 6.49971 1.5C6.77421 1.5 7.04336 1.57646 7.27693 1.72083C7.51043 1.86519 7.69914 2.07174 7.82193 2.31733ZM6.5 8.06243C6.25136 8.06243 6.01293 8.16122 5.83707 8.337C5.66129 8.51286 5.5625 8.75129 5.5625 8.99993C5.5625 9.24857 5.66129 9.48707 5.83707 9.66286C6.01293 9.83872 6.25136 9.93743 6.5 9.93743C6.74864 9.93743 6.98707 9.83872 7.16293 9.66286C7.33871 9.48707 7.4375 9.24857 7.4375 8.99993C7.4375 8.75129 7.33871 8.51286 7.16293 8.337C6.98707 8.16122 6.74864 8.06243 6.5 8.06243ZM6.5 7.12493C6.845 7.12493 7.125 6.92493 7.125 6.67865V4.44611C7.125 4.19986 6.845 3.99986 6.5 3.99986C6.155 3.99986 5.875 4.19986 5.875 4.44611V6.67865C5.875 6.92493 6.155 7.12493 6.5 7.12493Z" fill="#DC724A" style="fill:#DC724A;fill:color(display-p3 0.8627 0.4471 0.2902);fill-opacity:1;"/>
                        </svg>
                    </div>
                `,
                style: {
                    transform: 'none',
                }
            },
        });
    }

    addResolvePlotLine() {

        this.xAxis.removePlotLine('alert-resolve-actual')

        const resolveTime = moment(this.resolveEvent.createTime).valueOf()
        this.xAxis.addPlotLine({
            value: resolveTime, 
            id: 'alert-resolve-actual',
            className: 'resolve-actual',
            zIndex: 2,
            label: {
                useHTML: true,
                align: 'center',
                className: 'resolve-actual-label',
                y: -4,
                x: 0,
                allowOverlap: true,
                text: `
                    <div style="">
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.50178 11.818C9.43893 11.818 11.82 9.43697 11.82 6.49982C11.82 3.56267 9.43893 1.18164 6.50178 1.18164C3.56462 1.18164 1.18359 3.56267 1.18359 6.49982C1.18359 9.43697 3.56462 11.818 6.50178 11.818Z" fill="#86B68B" style="fill:#86B68B;fill:color(display-p3 0.5268 0.7148 0.5456);fill-opacity:1;"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.68301 5.37569C8.71864 5.32821 8.74442 5.2741 8.75882 5.21653C8.77324 5.15895 8.776 5.09908 8.76694 5.04043C8.75788 4.98177 8.7372 4.92552 8.70608 4.87498C8.67497 4.82444 8.63408 4.78063 8.58579 4.74611C8.53752 4.7116 8.48281 4.68709 8.42493 4.67402C8.36703 4.66095 8.30711 4.65959 8.24868 4.67C8.19025 4.68042 8.13451 4.70242 8.08469 4.73469C8.03489 4.76697 7.99204 4.80887 7.95866 4.85794L6.03732 7.54757L5.0731 6.58334C4.98868 6.50468 4.87704 6.46186 4.76167 6.46389C4.64631 6.46592 4.53624 6.51267 4.45465 6.59425C4.37306 6.67584 4.32633 6.78591 4.32429 6.90127C4.32226 7.01664 4.36508 7.12829 4.44373 7.2127L5.77965 8.54861C5.82536 8.59428 5.88046 8.62947 5.94113 8.65171C6.00181 8.67395 6.0666 8.68272 6.13099 8.67741C6.1954 8.6721 6.25787 8.65283 6.31408 8.62096C6.3703 8.58907 6.41889 8.54535 6.4565 8.49279L8.68301 5.37569Z" fill="white" style="fill:white;fill-opacity:1;"/>
                        </svg>
                    </div>
                `,
                style: {
                    transform: 'none',
                }
            },
        });
    }

    addYAxisPlotLines() {

        const thresholdValues = this.thresholds.split(',')

        const lowerYExtreme = isNaN(thresholdValues[0]) ? this.chart.yAxis[0].dataMin : thresholdValues[0] 
        const upperYExtreme = isNaN(thresholdValues[1]) ? this.chart.yAxis[0].dataMax : thresholdValues[1]

        const yAxisIndex = this.chart.yAxis.length > 2 ? 2 : 0  // Humidity chart has 2 Y-axes

        if (thresholdValues[0] !== 'undefined') {
            this.chart.yAxis[yAxisIndex]?.removePlotLine('lower'); // eslint-disable-line no-unused-expressions
            this.chart.yAxis[yAxisIndex]?.addPlotLine({ // eslint-disable-line no-unused-expressions
                value: thresholdValues[0],
                width: 2,
                id: 'lower',
                className: 'threshold',
                label: {
                    text: 'Lower Limit',
                    align: 'right',
                    x: -10,
                    y: 14
                },
                zIndex: 8
            })
        }
        if (thresholdValues[1]) {
            this.chart.yAxis[yAxisIndex]?.removePlotLine('upper'); // eslint-disable-line no-unused-expressions
            this.chart.yAxis[yAxisIndex]?.addPlotLine({ // eslint-disable-line no-unused-expressions
                value: thresholdValues[1],
                width: 2,
                id: 'upper',
                className: 'threshold',
                label: {
                    text: 'Upper Limit',
                    align: 'right',
                    x: -10
                },
                zIndex: 8
            })
        }

        // Ensure both data and plot-lines are with the Y-axis extremes
        const newLowExtreme = Math.min(Math.min(lowerYExtreme, this.chart.yAxis[0].dataMin), upperYExtreme)
        const newMaxExtreme = Math.max(Math.max(upperYExtreme, this.chart.yAxis[0].dataMax), lowerYExtreme)
        this.chart.yAxis[0].setExtremes(newLowExtreme, newMaxExtreme)

        this.currentLowerYExtreme = newLowExtreme
        this.currentUpperYExtreme = newMaxExtreme
    }
}

export default AbstractChartController;
